











import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class McmCard extends Vue {
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: false }) public loading!: boolean;
}

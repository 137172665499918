import { MutationTree } from "vuex";

import { IUiState, SnackbarView } from "./types";
import { TotalErrorMessage } from "../../../modules/mcm-vue-components";

export const mutations: MutationTree<IUiState> = {
  changeNavigation(state: IUiState, value: boolean): void {
    state.navigationOpen = value;
  },

  addSnackbarError(state: IUiState, error: SnackbarView): void {
    state.snackbars = [...state.snackbars.filter((s) => s.id !== error.id), error];
  },

  clearError(state: IUiState): void {
    state.error = null;
  },

  addError(state: IUiState, error: TotalErrorMessage): void {
    state.error = error;
  },

  pageRequiresAuth(state: IUiState, value: boolean): void {
    state.pageRequiresAuth = value;
  },

  removeSnackbarError(state: IUiState, id: number | string): void {
    state.snackbars = state.snackbars.filter((s) => s.id !== id);
  },

  selectOrganization(state: IUiState, id: string): void {
    state.selectedOrganizationId = id;
  },

  setIntroductionHidden(state: IUiState, value: boolean): void {
    state.introductionHidden = value;
  },
};

import { Component, Prop, Vue } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";

export interface FooterLink {
  external?: boolean;
  title: string | TranslateResult;
  url: string;
}

@Component({})
export default class McmPageFooter extends Vue {
  @Prop({ default: false }) public hiddenVersion!: boolean;
  @Prop() public version!: string;
  @Prop() public links!: FooterLink[];
}

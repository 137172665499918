










































































import { defineComponent, PropType } from "@vue/composition-api";

import ButtonWithConfirmation from "@/components/ButtonWithConfirmation.vue";
import UserInvitationDialog from "./UserInvitationDialog.vue";
import RadioWithInfo from "@/components/RadioWithInfo.vue";
import UserRole from "@/components/UserRole.vue";
import { Dictionary } from "@/types";
import { validations, McmButton, RequestError } from "../../../modules/mcm-vue-components";
import ValidationError from "@/repositories/ValidationError";
import { AccountType } from "@/models/AccountType";
import { ExistingOrganizationUiDto } from "@/models/ExistingOrganizationUiDto";
import { UserInOrganizationUiDto } from "@/models/UserInOrganizationUiDto";
import { OrganizationUserChangesUiDto } from "@/models/OrganizationUserChangesUiDto";
import { InvitationChangesUiDto } from "@/models/InvitationChangesUiDto";
import { waitFor } from "../../helpers";

export default defineComponent({
  name: "EditUserDialog",

  components: {
    ButtonWithConfirmation,
    RadioWithInfo,
    UserInvitationDialog,
    UserRole,
  },

  props: {
    user: {
      type: Object as PropType<UserInOrganizationUiDto> | null,
    },
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      backendErrors: {} as Dictionary<string>,
      isRemoving: false,
      isSaving: false,
      removeError: false,
      showInvitation: false,
      showRemoveDialog: false,
      submitError: null as RequestError<string> | null,

      accountType: this.user ? (this.user.accountType as AccountType) : AccountType.OutsideOrga,
      email: this.user ? (this.user.email as string) : "",
      name: this.user ? (this.user.name as string) : "",
    };
  },

  async mounted() {
    const orgaId = this.currentOrganizationId as string;
    this.fetchUserList(orgaId);
    await this.fetchOrganization(orgaId);
  },

  computed: {
    organization(): ExistingOrganizationUiDto {
      return this.$store.getters["organizations/organization"];
    },
    currentOrganizationId(): string | null {
      return this.$store.getters["currentOrganizationId"];
    },

    validations(): object {
      return {
        email: [
          validations.required(this.$t("views.members.addUserDialog.errors.emailRequired")),
          validations.email(this.$t("views.members.addUserDialog.errors.emailInvalid")),
        ],
        name: [validations.required(this.$t("views.members.addUserDialog.errors.nameRequired"))],
      };
    },

    isChanged(): boolean {
      return this.user
        ? this.accountType !== this.user.accountType || this.email !== this.user.email || this.name !== this.user.name
        : false;
    },

    accountsLink(): string {
      return window.location.origin;
    },

    managerLink(): string {
      return this.organization ? (this.organization.redirectUri as unknown as string) : "";
    },
  },

  methods: {
    fetchOrganization(orgaId: string): any {
      return this.$store.dispatch("organizations/fetchOrganization", orgaId);
    },
    fetchUserList(orgaId: string): any {
      return this.$store.dispatch("users/fetchUserList", orgaId);
    },
    updateOrganizationUser(params: { orgaId: string; userId: string; user: OrganizationUserChangesUiDto }): any {
      return this.$store.dispatch("users/updateOrganizationUser", params);
    },
    removeOrganizationUser(params: { orgaId: string; userId: string }): any {
      return this.$store.dispatch("users/removeOrganizationUser", params);
    },
    updateInvitation(params: { invitationId: string; invitation: InvitationChangesUiDto }): any {
      return this.$store.dispatch("users/updateInvitation", params);
    },
    removeInvitation(params: { orgaId: string; invitationId: string }): any {
      return this.$store.dispatch("users/removeInvitation", params);
    },
    raiseError(error: string): any {
      return this.$store.dispatch("ui/raiseError", error);
    },
    showInfo(info: string): any {
      return this.$store.dispatch("ui/showInfo", info);
    },

    async handleSubmit(): Promise<void> {
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.isSaving = true;
      this.submitError = null;
      this.backendErrors = {};
      try {
        if (!this.user) {
          return;
        }
        if (this.user.invitation) {
          await this.updateInvitation({
            invitationId: this.user.id,
            invitation: {
              name: this.name,
              email: this.email,
              accountType: this.accountType,
            },
          });
        } else {
          await this.updateOrganizationUser({
            orgaId: this.currentOrganizationId as string,
            userId: this.user.id,
            user: {
              name: this.name,
              accountType: this.accountType,
            },
          });
        }
        (this.$refs.submitButton as McmButton).showSuccess();
        await waitFor(300);
        this.handleClose();
      } catch (err) {
        if (err instanceof ValidationError) {
          this.backendErrors = err.validationErrors;
        } else if (err instanceof RequestError) {
          this.submitError = err;
        } else {
          this.raiseError("errors.requestFailed");
        }
      }
      this.isSaving = false;
    },

    async handleRemove(): Promise<void> {
      this.isRemoving = true;
      this.removeError = false;
      try {
        if (!this.user) {
          return;
        }
        const orgaId = this.currentOrganizationId as string;
        if (this.user.invitation) {
          await this.removeInvitation({
            orgaId,
            invitationId: this.user.id,
          });
        } else {
          await this.removeOrganizationUser({
            orgaId,
            userId: this.user.id,
          });
        }
        this.showRemoveDialog = false;
        await waitFor(300);
        this.handleClose();
      } catch (err) {
        this.removeError = true;
      }
      this.isRemoving = false;
    },

    handleUserChange(): void {
      this.showInvitation = false;
      if (this.user === null) {
        this.handleClose();
      } else {
        this.handleReset();
      }
    },

    handleClose(): void {
      if (this.showInvitation) {
        this.showInvitation = false;
      } else {
        this.$emit("input", false);
      }
    },

    handleReset(): void {
      if (!this.user) {
        return;
      }
      if (this.$refs.form) {
        (this.$refs.form as HTMLFormElement).resetValidation();
      }
      this.accountType = this.user.accountType;
      this.email = this.user.email;
      this.name = this.user.name;
      this.backendErrors = {};
      this.submitError = null;
    },
  },
  watch: {
    user() {
      this.handleUserChange();
    },
  },
});

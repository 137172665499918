




































import { Component, Vue, Prop } from "vue-property-decorator";
import McmCardHeader from "./McmCardHeader.vue";
import McmCardError from "./McmCardError.vue";
import StickToBottom from "./StickToBottom.vue";

@Component({
  components: {
    McmCardHeader,
    McmCardError,
    StickToBottom,
  },
})
export default class McmCard extends Vue {
  @Prop({ default: false }) public disabled!: boolean;
  @Prop() public title!: string;
  @Prop() public subtitle?: string;
  @Prop({ default: false }) public noPadding!: boolean;
  @Prop({ default: false }) public fullHeight!: boolean;
  @Prop({ default: false }) public loading!: boolean;
  @Prop({ default: false }) public airy!: boolean;
  @Prop() public error?: string;
  @Prop() public cardInfo?: string;
  @Prop() public errorAction?: string;

  @Prop({ default: false }) public stickyFooter!: boolean;

  public get footerComponent() {
    return this.stickyFooter ? StickToBottom : "div";
  }
  public get clickEventName() {
    // We have to conditionally set @click, so we don't see a ripple effect on all cards
    return this.$listeners.click ? "click" : "noClick";
  }
}

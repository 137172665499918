





























import { defineComponent } from "@vue/composition-api";

import PageHeader from "@/components/PageHeader.vue";
import { UserInOrganizationUiDto } from "@/models/UserInOrganizationUiDto";
import {
  ACTION_DOWNLOADS_PAGE_SHOW,
  ACTION_MANAGER_DOWNLOAD_SHOW,
  ACTION_MEMBERS_PAGE_SHOW,
  ACTION_OVERVIEW_PAGE_SHOW,
  ACTION_PROFILE_PAGE_SHOW,
} from "@/config/authActions";
import { CanDoMethod } from "@/store";
import setPageTitle from "@/compositions/setPageTitle";

interface Card {
  action: string;
  actionTitle: string;
  icon: string;
  text: string;
  title: string;
  visible: boolean;
}

export default defineComponent({
  name: "OverviewPage",
  components: {
    PageHeader,
  },

  beforeMount(): void {
    if (!this.canDo(ACTION_OVERVIEW_PAGE_SHOW)) {
      this.$router.replace({ name: "profile" });
    }
  },

  setup() {
    setPageTitle("views.overview.pageTitle");
  },

  computed: {
    profile(): UserInOrganizationUiDto {
      return this.$store.getters["users/profile"];
    },
    canDo(): CanDoMethod {
      return this.$store.getters["canDo"];
    },

    cards(): Card[] {
      return [
        {
          action: "profile",
          actionTitle: this.$t("views.overview.cards.profile.actionTitle").toString(),
          half: true,
          icon: "person",
          text: this.$t("views.overview.cards.profile.text").toString(),
          title: this.$t("views.overview.cards.profile.title").toString(),
          visible: this.canDo(ACTION_PROFILE_PAGE_SHOW),
        },
        {
          action: "members",
          actionTitle: this.$t("views.overview.cards.members.actionTitle").toString(),
          icon: "group",
          half: true,
          text: this.$t("views.overview.cards.members.text").toString(),
          title: this.$t("views.overview.cards.members.title").toString(),
          visible: this.canDo(ACTION_MEMBERS_PAGE_SHOW),
        },
        {
          action: this.canDo(ACTION_MANAGER_DOWNLOAD_SHOW) ? "managerDownload" : "createManager",
          actionTitle: this.$t("views.overview.cards.manager.actionTitle").toString(),
          half: !(this.canDo(ACTION_MEMBERS_PAGE_SHOW) && this.canDo(ACTION_PROFILE_PAGE_SHOW)),
          icon: "file_download",
          text: this.$t("views.overview.cards.manager.text").toString(),
          title: this.$t("views.overview.cards.manager.title").toString(),
          visible: this.canDo(ACTION_DOWNLOADS_PAGE_SHOW),
        },
        {
          action: "standaloneDownload",
          actionTitle: this.$t("views.overview.cards.firmware.actionTitle").toString(),
          half: !(this.canDo(ACTION_MEMBERS_PAGE_SHOW) && this.canDo(ACTION_PROFILE_PAGE_SHOW)),
          icon: "file_download",
          text: this.$t("views.overview.cards.firmware.text").toString(),
          title: this.$t("views.overview.cards.firmware.title").toString(),
          visible: this.canDo(ACTION_DOWNLOADS_PAGE_SHOW),
        },
        {
          action: "pluginsDownload",
          actionTitle: this.$t("views.overview.cards.thirdparty.actionTitle").toString(),
          half: !(this.canDo(ACTION_MEMBERS_PAGE_SHOW) && this.canDo(ACTION_PROFILE_PAGE_SHOW)),
          icon: "file_download",
          text: this.$t("views.overview.cards.thirdparty.text").toString(),
          title: this.$t("views.overview.cards.thirdparty.title").toString(),
          visible: this.canDo(ACTION_DOWNLOADS_PAGE_SHOW),
        },
      ].filter((x) => x.visible);
    },
  },

  methods: {
    goTo(action: string): void {
      this.$router.push({ name: action });
    },
  },
});

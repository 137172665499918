import { ApplicationInsights, DistributedTracingModes } from "@microsoft/applicationinsights-web";
import _Vue from "vue";
import Router from "vue-router";

export interface AppInsightsOptions {
  id?: string;
  router?: Router;
  trackInitialPageView?: boolean;
  appInsights?: ApplicationInsights;
  basePath?: string;
}

export function AppInsightsPlugin(Vue: typeof _Vue, options: AppInsightsOptions = { id: "" }): void {
  const { id, router } = options;

  if (options.appInsights) {
    Vue.appInsights = options.appInsights;
  } else {
    const appInsights = new ApplicationInsights({
      config: {
        distributedTracingMode: DistributedTracingModes.W3C,
        enableAutoRouteTracking: true,
        instrumentationKey: id,
      },
    });

    Vue.appInsights = appInsights;

    Vue.appInsights.loadAppInsights();
  }

  // Watch route event if router option is defined.
  if (router) {
    if (options.trackInitialPageView !== false) {
      setupPageTracking(Vue, router, options.basePath);
    } else {
      router.onReady(() => setupPageTracking(Vue, router, options.basePath));
    }
  }
}

/**
 * Track route changes as page views with AppInsights
 */
function setupPageTracking(Vue: typeof _Vue, router: Router, basePath = "(Application Root)") {
  const pathFormatter = (path: string) => basePath + "/" + path.substr(1);

  router.beforeEach((route, _from, next) => {
    const path = pathFormatter(route.fullPath);
    if (Vue.appInsights) {
      Vue.appInsights.startTrackPage(path);
    }
    next();
  });

  router.afterEach((route) => {
    const path = pathFormatter(route.fullPath);
    if (Vue.appInsights) {
      Vue.appInsights.stopTrackPage(path, route.fullPath);
    }
  });
}

import { Module } from "vuex";
import { IRootState } from "../types";
import { createActions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IOrganizationsState } from "./types";
import organizationRepository from "@/repositories/organizationRepository";

export const state: IOrganizationsState = {
  loading: false,
  updating: false,
  error: false,
  organization: null,
};

export const organizations: Module<IOrganizationsState, IRootState> = {
  namespaced: true,

  state,
  getters,
  actions: createActions(organizationRepository),
  mutations,
};

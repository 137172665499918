




import { defineComponent } from "@vue/composition-api";

import { SettingsUiDto } from "../models/SettingsUiDto";

export default defineComponent({
  name: "Logout",

  async beforeMount() {
    const settings = await this.fetchSettings();
    await this.signOut(settings);
  },

  methods: {
    fetchSettings(): SettingsUiDto {
      return this.$store.dispatch("system/fetchSettings") as unknown as SettingsUiDto;
    },

    signOut(settings: SettingsUiDto): void {
      this.$store.dispatch("identity/signOut", settings);
    },
  },
});

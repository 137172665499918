import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IUsersState } from "./types";
import { UserInOrganizationUiDto } from "@/models/UserInOrganizationUiDto";
import { AssignedOrganizationUiDto } from "@/models/AssignedOrganizationUiDto";
import { InvitationUiDto } from "@/models/InvitationUiDto";

export const getters: GetterTree<IUsersState, IRootState> = {
  invitations: (state: IUsersState): InvitationUiDto[] => state.invitations || [],

  isProfileErrornous: (state: IUsersState): boolean => state.profileLoadingError,
  isProfileLoading: (state: IUsersState): boolean => state.profileLoading,
  isProfileSaving: (state: IUsersState): boolean => state.updatingProfile,

  isUsersLoading: (state: IUsersState): boolean => state.usersLoading,

  getOrganization: (state: IUsersState): ((id: string) => AssignedOrganizationUiDto | undefined) => {
    return (id: string) => (state.organizations || []).find((org: AssignedOrganizationUiDto) => org.id === id);
  },
  didOrganizationsLoad: (state: IUsersState): boolean => state.organizationsDidLoad,
  organizations: (state: IUsersState): AssignedOrganizationUiDto[] => state.organizations || [],

  profile: (state: IUsersState): UserInOrganizationUiDto => state.profile,

  users: (state: IUsersState): UserInOrganizationUiDto[] => state.users,
};

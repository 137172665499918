





























































































import { defineComponent } from "@vue/composition-api";

import InvitationItem from "@/components/InvitationItem.vue";
import PageHeader from "@/components/PageHeader.vue";
import UserRole from "@/components/UserRole.vue";

import { UserInOrganizationUiDto } from "../models/UserInOrganizationUiDto";

import { McmButton, validations } from "../../modules/mcm-vue-components";

import { AssignedOrganizationUiDto } from "../models/AssignedOrganizationUiDto";
import { InvitationUiDto } from "../models/InvitationUiDto";
import { ACTION_PROFILE_PAGE_SHOW } from "@/config/authActions";
import setPageTitle from "@/compositions/setPageTitle";
import connectUserHub from "@/compositions/connectUserHub";

const { required } = validations;

export default defineComponent({
  name: "ProfilePage",

  components: {
    InvitationItem,
    PageHeader,
    UserRole,
  },

  data() {
    return {
      saveError: false,
      validation: {
        nameRequired: required(this.$t("views.profile.errors.required.name")),
      },
      writable: {
        name: "",
      } as UserInOrganizationUiDto,
    };
  },

  setup() {
    setPageTitle("views.profile.pageTitle");
    connectUserHub();
  },

  mounted() {
    this.fetchInvitations();
    this.fetchProfile();
  },

  computed: {
    isLoading(): boolean {
      return this.$store.getters["users/isProfileLoading"];
    },
    profile(): UserInOrganizationUiDto {
      return this.$store.getters["users/profile"];
    },
    isSaving(): boolean {
      return this.$store.getters["users/isProfileSaving"];
    },

    organizations(): AssignedOrganizationUiDto[] {
      return this.$store.getters["users/organizations"];
    },
    invitations(): InvitationUiDto[] {
      return this.$store.getters["users/invitations"];
    },
    canDoRule() {
      return ACTION_PROFILE_PAGE_SHOW;
    },

    showJoinTeaser(): boolean {
      return this.organizations.length === 0 && this.invitations.length === 0;
    },

    hasOrganizations(): boolean {
      return this.organizations.length > 0;
    },

    hasInvitations(): boolean {
      return this.invitations.length > 0;
    },

    isChanged(): boolean {
      return this.writable.name !== this.profile.name;
    },
  },

  watch: {
    profile() {
      this.handleUpdated();
    },
  },

  methods: {
    async handleReset(): Promise<void> {
      this.writable = { ...this.profile };
    },

    handleUpdated(): void {
      if (!this.isChanged || this.writable.name === "") {
        this.writable.name = this.profile.name;
      }
    },

    async handleSave(): Promise<void> {
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.saveError = false;
      try {
        await this.renameProfile(this.writable.name);
        (this.$refs.submitButton as McmButton).showSuccess();
      } catch (err) {
        this.saveError = true;
      }
    },

    fetchProfile(): void {
      this.$store.dispatch("users/fetchProfile");
    },
    renameProfile(name: string): void {
      this.$store.dispatch("users/renameProfile", name);
    },
    fetchInvitations(): void {
      this.$store.dispatch("users/fetchInvitations");
    },
  },
});

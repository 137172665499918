import Fragment from "./src/Fragment.vue";
import McmButton from "./src/McmButton.vue";
import McmCancelButton from "./src/McmCancelButton.vue";
import McmCanDo from "./src/McmCanDo.vue";
import McmCard from "./src/McmCard.vue";
import McmCardHeader from "./src/McmCardHeader.vue";
import McmCardSection from "./src/McmCardSection.vue";
import McmChip from "./src/McmChip.vue";
import McmConfirmButton from "./src/McmConfirmButton.vue";
import McmContent from "./src/McmContent.vue";
import McmCopyIcon from "./src/McmCopyIcon.vue";
import McmDangerButton from "./src/McmDangerButton.vue";
import McmDialog from "./src/McmDialog.vue";
import McmField from "./src/McmField.vue";
import McmHeader from "./src/McmHeader.vue";
import McmHeaderTabs from "./src/McmHeaderTabs.vue";
import { TabItem } from "./src/McmHeaderTabs";
import McmInfoIcon from "./src/McmInfoIcon.vue";
import McmLabel from "./src/McmLabel.vue";
import McmLogo from "./src/McmLogo.vue";
import McmNavigation from "./src/McmNavigation.vue";
import { NavigationItem, NavigationList } from "./src/McmNavigation";
import McmPinInput from "./src/McmPinInput.vue";
import McmPrimaryButton from "./src/McmPrimaryButton.vue";
import McmSearchInput from "./src/McmSearchInput.vue";
import McmSecondaryButton from "./src/McmSecondaryButton.vue";
import McmSidebarLayout from "./src/McmSidebarLayout.vue";
import McmErrorPage from "./src/McmErrorPage.vue";
import { PersistentErrorAction, PersistentErrorMessage, TotalErrorMessage } from "./src/McmErrorPage";
import McmForm from "./src/McmForm.vue";
import McmPageFooter from "./src/McmPageFooter.vue";
import McmUndoButton from "./src/McmUndoButton.vue";
import { FooterLink } from "./src/McmPageFooter";
import StickToBottom from "./src/StickToBottom.vue";

import theme from "./src/config/theme";

import * as validations from "./src/validations";
import type { ValidationResult, Validator } from "./src/validations/index";

import { downloadRequest } from "./src/helpers/download";
import { getJsonData, getJsonError, RequestError } from "./src/helpers/repository";

export {
  // Exporting components
  Fragment,
  McmButton,
  McmCanDo,
  McmCancelButton,
  McmCard,
  McmCardHeader,
  McmCardSection,
  McmChip,
  McmConfirmButton,
  McmContent,
  McmCopyIcon,
  McmDangerButton,
  McmDialog,
  McmErrorPage,
  McmField,
  McmHeader,
  McmHeaderTabs,
  McmInfoIcon,
  McmLabel,
  McmLogo,
  McmForm,
  McmNavigation,
  McmPageFooter,
  McmPinInput,
  McmPrimaryButton,
  McmSearchInput,
  McmSecondaryButton,
  McmSidebarLayout,
  McmUndoButton,
  StickToBottom,
  // Exporting helpers
  validations,
  downloadRequest,
  getJsonData,
  getJsonError,
  RequestError,
};

// Exporting types
export type {
  FooterLink,
  NavigationItem,
  NavigationList,
  PersistentErrorAction,
  PersistentErrorMessage,
  TabItem,
  TotalErrorMessage,
  ValidationResult,
  Validator,
};

export const config = {
  theme,
};

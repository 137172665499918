/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import VueRouter, { Route } from "vue-router";

import { AppInsightsPlugin, AppInsightsOptions } from "@/plugins/appInsights";
import store from "@/store/index";
import { SettingsUiDto } from "@/models/SettingsUiDto";

export type nextFunction = (config?: any) => void;

let settings: SettingsUiDto | null = null;

export const loadSettings =
  (router: VueRouter) =>
  async (_to: Route, _from: Route, next: nextFunction): Promise<void> => {
    store.dispatch("ui/hideErrorPage");
    if (store.getters["system/areSettingsLoaded"]) {
      return next();
    }
    try {
      settings = await store.dispatch("system/fetchSettings");
      // If backend sends us an insights id, we start up AppInsights
      if (settings && settings.applicationInsightsId) {
        Vue.use<AppInsightsOptions>(AppInsightsPlugin, {
          id: settings.applicationInsightsId,
          router,
          trackInitialPageView: false,
        });
      }
      next();
    } catch (e) {
      store.dispatch("ui/showErrorPage", {
        code: 500,
        subtitle: "errors.500.subtitle",
        title: "errors.500.title",
        message: "errors.500.message",
        action: {
          title: "errors.500.action",
          callback: () => window.location.reload(),
        },
        type: "UnexpectedError",
      });
    }
  };

export async function redirectForLoggedInOnly(to: Route, _from: Route, next: nextFunction): Promise<void> {
  if (!settings) {
    throw new Error("No settings loaded yet. Use middleware loadSettings before this one.");
  }

  if (!to.meta?.requiresAuth || to.meta?.isLoginRoute) {
    return next();
  }

  await store.dispatch("identity/init", settings);

  store.dispatch("ui/pageRequiresAuth", !!to.meta?.requiresAuth);

  if (!store.getters["identity/isAuthenticated"]) {
    try {
      await store.dispatch("identity/signIn", {
        settings,
        returnPath: window.location.pathname,
      });
      next();
    } catch (err) {
      if (err.errorCode === "endpoints_resolution_error") {
        store.dispatch("ui/showErrorPage", {
          code: 500,
          subtitle: "errors.noConnection.subtitle",
          title: "errors.noConnection.title",
          message: "errors.noConnection.message",
          type: "NoConnection",
        });
      }
    }
  } else {
    next();
  }
}

export async function redirectForLoggedOutOnly(to: Route, _from: Route, next: nextFunction): Promise<void> {
  if (!to.meta?.onlyLoggedOut || to.meta?.isLoginRoute) {
    return next();
  }

  if (!settings) {
    throw new Error("No settings loaded yet. Use middleware loadSettings before this one.");
  }

  await store.dispatch("identity/init", settings);

  if (store.getters["identity/isAuthenticated"]) {
    return next("/");
  }
  next();
}

// For the side navigation we need this
export async function loadOrgasForAuthorization(to: Route, _from: Route, next: nextFunction): Promise<void> {
  // If the page does not need authorization we can skip this
  if (!to.meta?.requiresAuth) {
    return next();
  }

  try {
    await store.dispatch("users/fetchOrganizations");
  } catch (err) {
    // An error here should not hinder page load
    console.warn("Error happened while dispatching users/fetchOrganizations:", err); // tslint:disable-line no-console
  }
  next();
}

export async function scrollToTop(to: Route, _from: Route, next: nextFunction): Promise<void> {
  if (to.meta?.scrollToTop) {
    window.scrollTo(0, 0);
  }
  next();
}

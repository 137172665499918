/**
 * This defines some colors and co for the Sennheiser theme
 */
export default {
  themes: {
    light: {
      ci: "#003746",
      info: "#0096d6",
      primary: "#0096d6",
    },
    dark: {
      ci: "#003746",
      info: "#0096d6",
      accent: "#0096d6",
    },
  },
};












import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";
import urls from "@/config/urls";

import DownloadCard from "./DownloadCard.vue";
import { ManagerVersionUiDto } from "@/models/ManagerVersionUiDto";

export default defineComponent({
  name: "StandaloneDownloadCard",

  components: {
    DownloadCard,
  },

  data() {
    return {
      checkbox1: false,
    };
  },

  mounted() {
    this.reload();
  },

  computed: {
    ...mapGetters({
      currentOrganizationId: "currentOrganizationId",
    }),

    checkboxes(): Array<(v: ManagerVersionUiDto) => string | false> {
      return [
        () =>
          this.$t("components.standaloneDownloadCard.privacyCheckboxLabel", {
            agreementLink: urls.storage.mcsV2Licence,
          }).toString(),
        (selectedVersion) => selectedVersion.isBeta && this.$t("components.downloadCard.betaCheckboxLabel").toString(),
      ];
    },

    standaloneVersions(): ManagerVersionUiDto[] {
      return this.$store.getters["system/standaloneVersions"][this.currentOrganizationId] || [];
    },

    standaloneBetaVersions(): ManagerVersionUiDto[] {
      return this.$store.getters["system/standaloneBetaVersions"][this.currentOrganizationId];
    },
  },

  methods: {
    fetchStandaloneVersion(): void {
      this.$store.dispatch("system/fetchStandaloneVersions", { orgaId: this.currentOrganizationId });
    },

    async reload() {
      await this.fetchStandaloneVersion();
    },
  },

  watch: {
    currentOrganizationId() {
      this.reload();
    },
  },
});

import { MutationTree } from "vuex";
import { ISystemState } from "./types";
import { SettingsUiDto } from "@/models/SettingsUiDto";
import { ManagerVersionUiDto } from "@/models/ManagerVersionUiDto";
import { StandaloneFirmwareVersionUiDto } from "@/models/StandaloneFirmwareVersionUiDto";

export const mutations: MutationTree<ISystemState> = {
  updateSettings(state: ISystemState, settings: SettingsUiDto): void {
    state.settings = settings;
  },

  setLoadingSettings(state: ISystemState, value: boolean): void {
    state.loadingSettings = value;
  },

  rememberLoadPromise(state: ISystemState, promise: Promise<SettingsUiDto>): void {
    state.settingsLoadPromise = promise;
  },

  setManagerVersions(
    state: ISystemState,
    {
      orgaId,
      versions,
      betaVersions,
    }: { orgaId: string; betaVersions: ManagerVersionUiDto[]; versions: ManagerVersionUiDto[] }
  ): void {
    state.managerVersions = {
      ...state.managerVersions,
      [orgaId]: versions,
    };
    state.managerBetaVersions = {
      ...state.managerVersions,
      [orgaId]: betaVersions,
    };
  },

  setManagerVersionsLoading(state: ISystemState, value: boolean): void {
    state.managerVersionsLoading = value;
  },

  setStandaloneVersions(
    state: ISystemState,
    {
      orgaId,
      versions,
      betaVersions,
    }: { orgaId: string; betaVersions: StandaloneFirmwareVersionUiDto[]; versions: StandaloneFirmwareVersionUiDto[] }
  ): void {
    state.standaloneVersions = {
      ...state.standaloneVersions,
      [orgaId]: versions,
    };
    state.standaloneBetaVersions = {
      ...state.standaloneVersions,
      [orgaId]: betaVersions,
    };
  },

  setStandaloneVersionsLoading(state: ISystemState, value: boolean): void {
    state.standaloneVersionsLoading = value;
  },
};














































import { defineComponent } from "@vue/composition-api";

import { SettingsUiDto } from "@/models/SettingsUiDto";
import { AssignedOrganizationUiDto } from "@/models/AssignedOrganizationUiDto";
import { UserInOrganizationUiDto } from "@/models/UserInOrganizationUiDto";

import OrganizationSelector from "@/components/OrganizationSelector.vue";

import OrganizationConnector, { createOrganizationConnector } from "@/repositories/OrganizationConnector";

export default defineComponent({
  name: "PageHeader",

  components: {
    OrganizationSelector,
  },

  props: {
    loading: { type: Boolean, default: false },
    to: { type: String, default: null },
    withOrgaSelector: { type: Boolean, default: false },
  },

  data() {
    return {
      connected: false,
      organizationConnector: createOrganizationConnector() as OrganizationConnector,
    };
  },

  mounted() {
    this.connectAuthStuff();
  },

  updated() {
    this.connectAuthStuff();
  },

  beforeDestroy() {
    if (this.connected) {
      this.organizationConnector.disconnect();
    }
  },

  computed: {
    isAuthenticated(): boolean {
      return this.$store.getters["identity/isAuthenticated"];
    },

    organization(): AssignedOrganizationUiDto | null {
      return this.$store.getters["organization"];
    },

    settings(): SettingsUiDto {
      return this.$store.getters["system/settings"];
    },

    profile(): UserInOrganizationUiDto {
      return this.$store.getters["users/profile"];
    },
    didOrganizationsLoad(): boolean {
      return this.$store.getters["users/didOrganizationsLoad"];
    },
  },

  methods: {
    signOut(settings: SettingsUiDto): void {
      this.$store.dispatch("identity/signOut", settings);
    },

    openNavigation(): void {
      this.$store.dispatch("ui/openNavigation");
    },

    fetchProfile(): void {
      this.$store.dispatch("users/fetchProfile");
    },

    async logOut(): Promise<void> {
      await this.signOut(this.settings);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 333);
      });
    },

    connectAuthStuff(): void {
      if (this.isAuthenticated && !this.connected) {
        this.organizationConnector.connect();
        this.fetchProfile();
        this.connected = true;
      }
    },
  },
});

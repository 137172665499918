import Vue from "vue";
import Vuetify from "vuetify/lib";
import icons from "../config/icons";
import theme from "../config/theme";

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    ...icons,
    iconfont: "md",
  },
  theme,
});

import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IOrganizationsState } from "./types";
import { ExistingOrganizationUiDto } from "@/models/ExistingOrganizationUiDto";

export const getters: GetterTree<IOrganizationsState, IRootState> = {
  isErrornous: (state: IOrganizationsState): boolean => state.error,
  isLoading: (state: IOrganizationsState): boolean => state.loading,
  isOrgaReady: (state: IOrganizationsState): boolean =>
    !!(state.organization && state.organization.id && state.organization.applicationId),

  organization: (state: IOrganizationsState): ExistingOrganizationUiDto | null => state.organization || null,
};

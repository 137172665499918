import { TranslateResult } from "vue-i18n";

export type ValidationResult = true | TranslateResult;
export type Validator<T = string> = (value: T) => ValidationResult;

export function required(errorMsg: TranslateResult): Validator<string | number> {
  return (value: string | number): ValidationResult => {
    if (typeof value === "string") {
      return !!(value || "").trim() || errorMsg;
    } else {
      return !!value || errorMsg;
    }
  };
}

export function email(errorMsg: TranslateResult): Validator {
  return (value: string): ValidationResult => {
    const trimmedVal = value.trim();
    if (trimmedVal.length === 0) {
      return true;
    }

    if (/^[^@]+@[^@]+\.[^@]+$/.exec(trimmedVal)) {
      return true;
    }
    return errorMsg;
  };
}

export function minLength(len: number, errorMsg: TranslateResult): Validator {
  return (value: string): ValidationResult => (value || "").length >= len || errorMsg;
}

export function maxLength(len: number, errorMsg: TranslateResult): Validator {
  return (value: string): ValidationResult => (value || "").length <= len || errorMsg;
}

const IP_REGEX = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
export function ip(errorMsg: TranslateResult): Validator {
  return (value: string): ValidationResult => {
    if (!value) {
      return true;
    }
    if (!IP_REGEX.test(value)) {
      return errorMsg;
    }

    const parts = value.split(".").map((n: string) => parseInt(n, 10));

    if (parts.some((p: number) => p < 0 || p > 255)) {
      return errorMsg;
    }
    if (parts.every((p: number) => p === 0)) {
      return errorMsg;
    }

    return true;
  };
}

const NETMASK_REGEX = /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/;
export function netmask(errorMsg: TranslateResult): Validator {
  return (value: string): ValidationResult => !value || NETMASK_REGEX.test(value) || errorMsg;
}





















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

const TRANSITION_LENGTH = 300;

@Component
export default class McmSearchInput extends Vue {
  @Prop() public initialValue!: string;
  @Prop() public label!: string;
  @Prop({ default: "searchPlaceholder" }) public placeholderKey!: string;
  public open = false;
  public expanded = false;
  public fullyExpanded = false;
  public text = "";
  public fieldIcon = "search";

  public data(): object {
    return {
      text: this.initialValue,
    };
  }

  public mounted() {
    if (this.text) {
      this.open = true;
      this.fullyExpanded = true;
      this.expanded = true;
    }
  }

  public openSearch() {
    this.open = true;
    this.$nextTick(() => {
      (this.$refs.input as HTMLInputElement).focus();
      this.expanded = true;
    });
    window.setTimeout(() => {
      this.fullyExpanded = true;
    }, TRANSITION_LENGTH);
  }

  public handleBlur() {
    if (!this.text) {
      this.expanded = false;
      this.fullyExpanded = false;
      window.setTimeout(() => {
        this.open = false;
      }, TRANSITION_LENGTH);
    }
  }

  @Watch("text")
  public handleChange() {
    this.$emit("input", this.text);
  }
}












import { defineComponent } from "@vue/composition-api";

import StandaloneDownloadCard from "@/components/StandaloneDownloadCard.vue";

export default defineComponent({
  name: "StandaloneDownload",

  components: {
    StandaloneDownloadCard,
  },
});

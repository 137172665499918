/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dictionary } from "@/types";

function firstToLower(str: string): string {
  if (str.length < 1) {
    return "";
  }

  const firstChar = str.substr(0, 1);
  const rest = str.substr(1);
  return firstChar.toLowerCase() + rest;
}

function mapValidationErrors(memo: any, err: any): Dictionary<string> {
  memo[firstToLower(err.propertyName)] = err.errorMessage;
  return memo;
}

export default class ValidationError extends Error {
  private memoizedValidationErrors: any;
  private responseData: any;

  constructor(error: Error) {
    const responseData = (error as any).response.data;

    super(responseData.detail);
    this.name = "ValidationError";

    this.responseData = responseData;
  }

  public get validationErrors(): any {
    if (!this.memoizedValidationErrors) {
      this.memoizedValidationErrors = this.responseData.errors.reduce(mapValidationErrors, {});
    }
    return this.memoizedValidationErrors;
  }

  public errorFor(field: string): string | null {
    return this.validationErrors[field] || null;
  }
}

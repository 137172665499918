import { Vue, Component, Prop } from "vue-property-decorator";

import McmContent from "./McmContent.vue";
import McmLogo from "./McmLogo.vue";
import McmPrimaryButton from "./McmPrimaryButton.vue";

import NoConnectionLight from "./assets/error_images/no_internet_darkgrey.svg";
import NoConnectionDark from "./assets/error_images/no_internet_white.svg";
import UnexpectedErrorLight from "./assets/error_images/unexpected_error_darkgrey.svg";
import UnexpectedErrorDark from "./assets/error_images/unexpected_error_white.svg";
import NotFoundLight from "./assets/error_images/micdrop_darkgrey.svg";
import NotFoundDark from "./assets/error_images/micdrop_white.svg";

export interface Dictionary<T> {
  [key: string]: T;
}

export interface PersistentErrorAction {
  title: string;
  callback: () => any;
}
export interface PersistentErrorMessage {
  id: string;
  message: string;
  action?: PersistentErrorAction;
}
export interface TotalErrorMessage {
  action?: PersistentErrorAction;
  code: number;
  message?: string;
  subtitle: string;
  title: string;
  type: "NotFound" | "NoConnection" | "UnexpectedError";
}

interface ErrorImageCollection {
  [key: string]: any;
}
interface ErrorImageCollectionList {
  [key: string]: ErrorImageCollection;
}

const ERROR_IMAGES: ErrorImageCollectionList = {
  dark: {
    NoConnection: NoConnectionDark,
    NotFound: NotFoundDark,
    UnexpectedError: UnexpectedErrorDark
  },
  light: {
    NoConnection: NoConnectionLight,
    NotFound: NotFoundLight,
    UnexpectedError: UnexpectedErrorLight
  }
};

@Component({
  components: {
    McmContent,
    McmLogo,
    McmPrimaryButton
  }
})
export default class McmErrorPage extends Vue {
  @Prop({ default: false }) public readonly inline!: boolean;
  @Prop() public readonly logoTitle!: string;
  @Prop() public readonly error!: TotalErrorMessage;

  public get dark(): boolean {
    return (this as any).$vuetify.theme.dark;
  }

  public get errorImage(): Vue {
    return ERROR_IMAGES[this.dark ? "dark" : "light"][this.error.type];
  }
}

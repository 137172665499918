/**
 * This is the abstraction layer that should allow access to localStorage.
 * Define everything here that should be stored, and how to serialize and
 * desrialize it.
 */
export class LocalStorage {
  get darkMode(): boolean {
    return localStorage.getItem("darkMode") !== "false";
  }
  set darkMode(value: boolean) {
    localStorage.setItem("darkMode", value ? "true" : "false");
  }

  get introductionHidden(): boolean {
    return localStorage.getItem("introductionHidden") === "true";
  }
  set introductionHidden(value: boolean) {
    localStorage.setItem("introductionHidden", value ? "true" : "false");
  }

  get loginLoopData(): string | null | undefined {
    return localStorage.getItem("loginLoopData");
  }
  set loginLoopData(value: string | null | undefined) {
    localStorage.setItem("loginLoopData", value || "");
  }

  get loginReturnPath(): string | null | undefined {
    return localStorage.getItem("loginReturnPath");
  }
  set loginReturnPath(value: string | null | undefined) {
    localStorage.setItem("loginReturnPath", value || "");
  }

  get currentOrganizationId(): string | null {
    return localStorage.getItem("currentOrganizationId");
  }
  set currentOrganizationId(value: string | null) {
    localStorage.setItem("currentOrganizationId", value || "");
  }
}

export default new LocalStorage();


















import { Component, Prop, Vue } from "vue-property-decorator";

import Navigation, { NavigationList } from "./McmNavigation.vue";

@Component({
  components: {
    Navigation,
  },
})
export default class McmSidebarLayout extends Vue {
  @Prop({
    default: 295,
  })
  public width!: number;
  @Prop({
    default: 960,
  })
  public permanentWidth!: number;
  @Prop() public isAuthenticated!: boolean;
  @Prop({
    default: false,
  })
  public alwaysShow!: boolean;
  @Prop() public navigationItems!: NavigationList;

  public get showSidebar(): boolean {
    return this.isAuthenticated || this.alwaysShow;
  }
}


















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ButtonWithConfirmation",

  props: {
    buttonClass: { type: String, default: "" },
    buttonText: { type: String, required: true },
    dialogDescription: { type: String, default: "" },
    dialogTitle: { type: String, required: true },
    value: { type: Boolean, default: false },
    isPerforming: { type: Boolean, default: false },
    submitButton: { type: String, required: true },
    maxWidth: { type: Number, default: 500 },
    primaryButton: { type: Boolean, default: false },
    dangerButton: { type: Boolean, default: false },
  },

  computed: {
    buttonColor(): string {
      // Secondary is default
      if (this.primaryButton !== false) {
        return "primary";
      }
      if (this.dangerButton !== false) {
        return "error";
      }
      return "";
    },
  },
});








import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class McmCardError extends Vue {
  @Prop() public text!: string;
  @Prop({ default: "alert" }) public type!: string;
  @Prop({ default: "" }) public action!: string;
}









































import { Vue, Component, Prop } from "vue-property-decorator";
import { VNode } from "vue";

import McmLogo from "./McmLogo.vue";

@Component({
  components: { McmLogo },
})
export default class McmHeader extends Vue {
  @Prop() public noNavigation!: boolean;
  @Prop() public subline!: VNode;
  @Prop() public loading!: boolean | number;

  public get showExtension(): boolean {
    return !!this.$slots.subline;
  }

  public get indeterminateLoading(): boolean {
    return this.loading === true;
  }

  public openNavigation() {
    this.$emit("openNavigation");
  }
}

import VueI18n from "vue-i18n";
import i18n from "./config/i18n";

type ParameterlessConstructor<T> = new () => T;

export function createSingletonFactory<T>(ctor: ParameterlessConstructor<T>): () => T {
  let instance: T | null = null;

  return () => {
    if (!instance) {
      instance = new ctor();
    }
    return instance;
  };
}

export function waitFor(ms: number): Promise<void> {
  return new Promise((resolve) => {
    window.setTimeout(resolve, ms);
  });
}

export interface IntermediaryUseI18n {
  t(key: VueI18n.Path, values?: VueI18n.Values): VueI18n.TranslateResult;
}

/**
 * Exposes methods like t, that will be similar to what v9 of VueI18n.useI18n will provide.
 * We need to remap this, since just return i18n will result in "this is undefined" errors,
 * when unspreading the methods.
 */
export function useI18n(): IntermediaryUseI18n {
  return {
    t(key: VueI18n.Path, values?: VueI18n.Values) {
      return i18n.t(key, values);
    },
  };
}






















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class McmCardHeader extends Vue {
  @Prop() public subtitle?: string;
  @Prop({ default: false }) public airy!: boolean;
}

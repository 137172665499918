var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mcm-dialog',{attrs:{"title":_vm.$t('views.members.addUserDialog.confirmation.title')},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"qa-close-btn",attrs:{"text":""},on:{"click":function($event){$event.preventDefault();return _vm.handleClose.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("actions.close")))])]},proxy:true}])},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('views.members.addUserDialog.confirmation.description', { email: _vm.email }))}}),_c('h4',{staticClass:"copy-headline"},[_vm._v(_vm._s(_vm.$t("views.members.addUserDialog.confirmation.label")))]),_c('p',{staticClass:"email-text",domProps:{"innerHTML":_vm._s(
      _vm.$t('views.members.addUserDialog.confirmation.emailText', {
        email: _vm.email,
        name: _vm.name,
        accountsLink: _vm.accountsLink,
        profileLink: _vm.profileLink,
        managerLink: _vm.managerLink,
      })
    )}})])}
var staticRenderFns = []

export { render, staticRenderFns }
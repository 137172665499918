















import { Component, Prop, Vue } from "vue-property-decorator";
import McmLabel from "./McmLabel.vue";

@Component({
  components: {
    McmLabel,
  },
})
export default class McmField extends Vue {
  @Prop({ default: "" }) public className!: string;
  @Prop() public label!: string;
  @Prop() public onlyButton!: boolean;
}

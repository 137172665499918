import { AxiosInstance } from "axios";

export async function downloadRequest(
  repository: AxiosInstance,
  url: string,
  defaultName = "MobileConnectManager.zip",
  config = {}
): Promise<void> {
  const response = await repository.get(url, { ...config, responseType: "blob" });

  const objectUrl = window.URL.createObjectURL(response.data);
  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = getFilenameFromHeader(response.headers, defaultName);
  link.click();

  window.URL.revokeObjectURL(objectUrl);
}

const FILENAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

function getFilenameFromHeader(headers: any, filename: string): string {
  const matches = FILENAME_REGEX.exec(headers["Content-Disposition"] || headers["content-disposition"]);
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, "");
  }
  return filename;
}

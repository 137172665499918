import localStorage, { LocalStorage } from "@/store/localStorage";

// 4 times in 10 seconds
const LOOP_COUNT = 4;
const LOOP_THRESHOLD_IN_MS = 10000;

export interface LoginLoopData {
  loginTimes: number[];
}

export default class LoginLoopDetector {
  private storage: LocalStorage;

  constructor(storage: LocalStorage = localStorage) {
    this.storage = storage;

    this.updateStorage();
  }

  public isLooping(): boolean {
    return this.data.loginTimes.length >= LOOP_COUNT;
  }

  public reset(): void {
    const data: LoginLoopData = { loginTimes: [] };
    this.storage.loginLoopData = JSON.stringify(data);
  }

  private get data(): LoginLoopData {
    let data: LoginLoopData;
    try {
      data = JSON.parse(this.storage.loginLoopData || "");
      if (!data.loginTimes) {
        data.loginTimes = [];
      }
    } catch (err) {
      data = { loginTimes: [] };
    }
    return data;
  }

  private updateStorage() {
    const data = this.data;
    const now = Date.now();
    const thresholdTime = now - LOOP_THRESHOLD_IN_MS;
    data.loginTimes = data.loginTimes.filter((t: number) => t >= thresholdTime);
    data.loginTimes.push(now);
    this.storage.loginLoopData = JSON.stringify(data);
  }
}

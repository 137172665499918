const host: string = process.env.VUE_APP_BACKEND_HOST || "";

export default {
  api: {
    betaAccess: {
      organizations: `${host}/api/v1.0/beta/organizations`,
      update: (id: string): string => `${host}/api/v1.0/beta/organizations/${encodeURIComponent(id)}`,
    },
    documents: `${host}/api/v1.0/documents/licenses`,
    download: (id: string, version: string): string =>
      `${host}/api/v1.0/organizations/${encodeURIComponent(id)}/download?version=${encodeURIComponent(version)}`,
    invitations: {
      accept: (id: string): string => `${host}/api/v1.0/invitations/${encodeURIComponent(id)}/accept`,
      all: `${host}/api/v1.0/invitations`,
      create: (orgaId: string): string => `${host}/api/v1.0/organizations/${encodeURIComponent(orgaId)}/invitations`,
      edit: (id: string): string => `${host}/api/v1.0/invitations/${encodeURIComponent(id)}`,
      reject: (id: string): string => `${host}/api/v1.0/invitations/${encodeURIComponent(id)}/reject`,
      remove: (orgaId: string, id: string): string =>
        `${host}/api/v1.0/organizations/${encodeURIComponent(orgaId)}/invitations/${encodeURIComponent(id)}`,
    },
    versions: {
      manager: (orgaId: string): string =>
        `${host}/api/v1.0/organizations/${encodeURIComponent(orgaId)}/manager/versions`,
      managerInternal: (): string => `${host}/api/v1.0/manager/versions/internal`,
      standalone: (orgaId: string): string =>
        `${host}/api/v1.0/organizations/${encodeURIComponent(orgaId)}/firmware/standalone/versions`,
      standaloneWithoutOrga: (): string => `${host}/api/v1.0/firmware/standalone/versions`,
    },
    organizations: {
      all: `${host}/api/v1.0/organizations`,
      get: (id: string): string => `${host}/api/v1.0/organizations/${encodeURIComponent(id)}`,
      update: (id: string): string => `${host}/api/v1.0/organizations/${encodeURIComponent(id)}`,
      users: (id: string): string => `${host}/api/v1.0/organizations/${encodeURIComponent(id)}/users`,
      patchUser: (id: string, userId: string): string =>
        `${host}/api/v1.0/organizations/${encodeURIComponent(id)}/users/${userId}`,
      removeUser: (id: string, userId: string): string =>
        `${host}/api/v1.0/organizations/${encodeURIComponent(id)}/users/${userId}`,
    },
    plugins: (orgaId: string | null = null): string =>
      orgaId
        ? `${host}/api/v1.0/organizations/${encodeURIComponent(orgaId)}/externalModules`
        : `${host}/api/v1.0/externalModules`,
    users: {
      profile: `${host}/api/v1.0/users`,
    },
    settings: `${host}/api/v1.0/settings`,
  },
  docs: {
    releaseNotes: (baseUrl: string): string => `${baseUrl}release-notes/index.html`,
  },
  hub: {
    organizations: `${host}/hubs/organizationsHub`,
    users: `${host}/hubs/usersHub`,
  },
  storage: {
    mcmLicence: "https://assets.sennheiser-connect.com/stable/docs/license/SE_MCM.pdf",
    mcsV1Licence: "https://assets.sennheiser-connect.com/stable/docs/license/SE_MCSv1.pdf",
    mcsV2Licence: "https://assets.sennheiser-connect.com/stable/docs/license/SE_MCSv2.pdf",
  },
};

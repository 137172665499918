





























import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import McmCopyIcon from "./McmCopyIcon.vue";

const NON_DIGIT_REGEX = /[^0-9]/g;
const DEFAULT_PIN_SIZE = 6;
const SHOW_SUCCESS_TIME = 2000;

@Component({
  components: {
    McmCopyIcon,
  },
})
export default class McmPinInput extends Vue {
  @Prop() public value!: string;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: false }) public copyIcon!: boolean;
  @Prop({ default: DEFAULT_PIN_SIZE }) public length!: number;
  @Prop({ default: () => [] }) public rules!: Function[];
  public digits: string[] = [];
  public successful = false;
  public successfulTimer?: number;
  public errorMsg: string | null = null;

  public data(): object {
    return {
      digits: this.resetDigits(),
    };
  }

  public validate(): boolean {
    if (!this.rules.length) {
      return true;
    }
    this.errorMsg = this.rules
      .map((fun: Function) => fun.call(window, this.value))
      .find((result: boolean | string) => typeof result === "string");
    return !this.errorMsg;
  }

  protected handleFocus(_event: KeyboardEvent, i: number) {
    this.getInputRef(i)!.select();
  }

  protected handleKeyUp(event: KeyboardEvent, i: number) {
    this.sanitizeInputs();
    switch (event.key) {
      case "Delete":
        break;
      case "ArrowLeft":
      case "Backspace":
        this.focusField(i - 1);
        break;
      default:
        this.focusField(i + 1);
    }
    this.emitChange();
  }

  protected recheckErrorMessage(): void {
    if (this.errorMsg) {
      this.validate();
    }
  }

  protected sanitizeInputs() {
    let values = [];
    for (var i = 0; i < this.length; ++i) {
      values.push(this.getInputRef(i)!.value);
    }
    const value = values.join("").replaceAll(NON_DIGIT_REGEX, "").substr(0, this.length);
    this.digits = value.split("");
  }

  protected handleIconClick() {
    window.navigator.clipboard.writeText(this.digits.join("")).then(() => this.showSuccess());
  }

  @Watch("value")
  protected updateValue() {
    this.digits = this.resetDigits();
    this.recheckErrorMessage();
  }

  private resetDigits() {
    const digits = new Array(this.length);
    const filled = this.value.split("");
    for (var i = 0; i < filled.length; ++i) {
      digits[i] = filled[i];
    }
    return digits;
  }

  private getInputRef(i: number): HTMLInputElement | null {
    return ((this.$refs.input as HTMLInputElement[])[i] as any)?.$el?.querySelector("input");
  }

  private focusField(i: number) {
    const nextField = this.getInputRef(i);
    if (nextField) {
      nextField.focus();
    }
  }

  private emitChange() {
    const value = this.digits.join("");
    this.$emit("input", value);
  }

  private showSuccess() {
    if (this.successfulTimer) {
      window.clearTimeout(this.successfulTimer);
    }
    this.successful = true;
    this.successfulTimer = window.setTimeout(() => {
      this.successful = false;
    }, SHOW_SUCCESS_TIME);
  }
}


















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ButtonWithConfirmation",

  props: {
    dialogDescription: { type: String, default: "" },
    dialogTitle: { type: String },
    value: { type: Boolean },
    isPerforming: { type: Boolean },
    submitButton: { type: String },
    maxWidth: { type: Number, default: 500 },
  },
});

// tslint:disable

/**
 *
 */
export enum ChangeAction {
  /**
   *
   */
  Add = 0,

  /**
   *
   */
  Remove = 1,

  /**
   *
   */
  Update = 2,
}























import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";

import ManagerDownloadCard from "@/components/ManagerDownloadCard.vue";
import OrganizationCard from "@/components/OrganizationCard.vue";

export default defineComponent({
  name: "Manager",

  components: {
    ManagerDownloadCard,
    OrganizationCard,
  },

  computed: {
    ...mapGetters({
      isErrornous: "organizations/isErrornous",
      isProductionEnv: "system/isProductionEnv",
    }),

    showFeaturesEnabled(): boolean {
      return !!this.$router.currentRoute.query?.showFeatures && !this.isProductionEnv;
    },
  },
});

import { Module } from "vuex";
import { IRootState } from "../types";
import { createActions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { ISystemState } from "./types";
import systemRepository from "@/repositories/systemRepository";

export const state: ISystemState = {
  loadingSettings: false,
  managerBetaVersions: {},
  managerVersions: {},
  managerVersionsLoading: false,
  standaloneBetaVersions: {},
  standaloneVersions: {},
  standaloneVersionsLoading: false,
  settings: null,
};

export const system: Module<ISystemState, IRootState> = {
  namespaced: true,

  state,
  getters,
  actions: createActions(systemRepository),
  mutations,
};

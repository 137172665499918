










import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class McmInfoIcon extends Vue {
  @Prop() public text!: string;
  @Prop({
    default: "18",
  })
  public size!: string;
  @Prop({
    default: false,
  })
  public textAsHtml!: boolean;
}
























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class McmConfirmButton extends Vue {
  @Prop() public color!: string;
  @Prop() public disabled!: boolean;
  @Prop() public small!: boolean;
  @Prop({ default: null }) public doneText!: string;
  @Prop() public sending!: boolean;
  @Prop() public type!: string;
  public successful!: boolean;

  public data(): object {
    return {
      successful: false,
    };
  }

  public mounted() {
    this.$nextTick(() => {
      const button = (this.$refs.button as any)?.$el as HTMLElement;
      const computedStyles = window.getComputedStyle(button);

      button.style.width = `${computedStyles.width}px`;
    });
  }

  public showSuccess() {
    this.successful = true;
    setTimeout(() => {
      this.successful = false;
    }, 2000);
  }
}

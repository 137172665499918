
























import { defineComponent } from "@vue/composition-api";

import { ExistingOrganizationUiDto } from "@/models/ExistingOrganizationUiDto";

export default defineComponent({
  name: "UserInvitationDialog",

  props: {
    email: {
      type: String,
    },
    name: {
      type: String,
    },
  },

  computed: {
    organization(): ExistingOrganizationUiDto {
      return this.$store.getters["organizations/organization"];
    },

    accountsLink(): string {
      return window.location.origin;
    },

    profileLink(): string {
      return window.location.origin + "/profile";
    },

    managerLink(): string {
      return this.organization ? (this.organization.redirectUri as unknown as string) : "";
    },
  },

  methods: {
    raiseError(error: string): any {
      return this.$store.dispatch("ui/raiseError", error);
    },
    showInfo(info: string): any {
      return this.$store.dispatch("ui/showInfo", info);
    },
    handleClose(): void {
      this.$emit("close");
    },
  },
});

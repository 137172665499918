


























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class McmForm extends Vue {
  @Prop({ default: "" }) public dialogDescription!: string;
  @Prop({ default: null }) public submitDialogTitle!: string | null;
  @Prop() public isPerforming!: string;
  @Prop({ default: 500 }) public dialogMaxWidth!: number;

  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: false }) public fullHeight!: boolean;
  @Prop({ default: null }) public extraValidation!: () => boolean;

  public showConfirmationDialog = false;

  public resetValidation(): void {
    (this.$refs.form as HTMLFormElement).resetValidation();
  }

  protected handleSubmit(): void {
    const form = this.$refs.form as HTMLFormElement;

    if (!(this.extraValidation ? this.extraValidation() : true) || (form.validate && !form.validate())) {
      return;
    }
    if (this.submitDialogTitle) {
      this.showConfirmationDialog = true;
    } else {
      this.$emit("submit");
    }
  }

  protected async confirmSubmit(event: any): Promise<void> {
    event.preventDefault();
    await this.$emit("submit", event);
    this.showConfirmationDialog = false;
  }
}

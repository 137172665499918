import Vue from "vue";
import { ActionTree } from "vuex";

import { IRootState } from "../types";
import { IIdentityState } from "./types";
import MsalAuthService, { LoginToken } from "@/services/MsalAuthService";
import { SettingsUiDto } from "@/models/SettingsUiDto";

function useSignInSignOutAuthority(settings: SettingsUiDto) {
  return {
    ...settings,
    authority: settings.signInSignOutAuthority,
  };
}

export const createActions = (AuthService: typeof MsalAuthService): ActionTree<IIdentityState, IRootState> => ({
  async init({ commit, state, dispatch }, settings: SettingsUiDto) {
    if (!state.initialized) {
      let authService;
      try {
        authService = new AuthService(useSignInSignOutAuthority(settings));
      } catch (error) {
        // that smells…
        dispatch("ui/raiseError", "errors.authInitFailed");
        if (Vue.appInsights) {
          Vue.appInsights.trackException({ exception: error });
        }
        return;
      }
      commit("setInitialized");
      try {
        const token = await authService.getToken();
        if (token) {
          commit("setUserCore", { user: authService.getUser(), token });
        }
      } catch (error) {
        // User is not yet signed in, that is total ok.
      }
    }
  },

  async signIn(_context, { settings, returnPath }: { settings: SettingsUiDto; returnPath: string }) {
    const authService = new AuthService(useSignInSignOutAuthority(settings));
    return authService.login({ returnPath });
  },

  async signOut({ commit }, settings: SettingsUiDto) {
    const authService = new AuthService(useSignInSignOutAuthority(settings));
    authService.logout();
    commit("deleteUserCore");
  },

  async refreshAuthToken({ commit, state }, settings: SettingsUiDto): Promise<void> {
    const authService = new AuthService(useSignInSignOutAuthority(settings));
    const token: LoginToken | null = await authService.getToken();
    if (token) {
      commit("setUserCore", { user: state.user, token });
    }
  },

  async markAsLoggedOut({ commit }) {
    commit("deleteUserCore");
  },
});

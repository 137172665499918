import { GetterTree } from "vuex";

import { OrganizationManagerVersionList, OrganizationStandaloneVersionList } from "@/types";
import { IRootState } from "../types";
import { ISystemState } from "./types";
import { SettingsUiDto } from "@/models/SettingsUiDto";
import { FeatureFlagUiDto } from "@/models/FeatureFlagUiDto";

export type FeatureEnabled = (key: string) => boolean;

export const getters: GetterTree<ISystemState, IRootState> = {
  areSettingsLoaded(state: ISystemState): boolean {
    return !!state.settings;
  },

  backendVersion(state: ISystemState): string {
    const { settings } = state;

    return settings ? settings.backendVersion : "";
  },

  managerVersions(state: ISystemState): OrganizationManagerVersionList {
    return state.managerVersions;
  },

  managerBetaVersions(state: ISystemState): OrganizationManagerVersionList {
    return state.managerBetaVersions;
  },

  settings(state: ISystemState): SettingsUiDto | null {
    return state.settings;
  },

  isProductionEnv(state: ISystemState): boolean {
    return state.settings?.environment === "Production";
  },

  documentationUrl(state: ISystemState): string {
    return state.settings?.documentationUrl || "https://docs.sennheiser-connect.com";
  },

  standaloneVersions(state: ISystemState): OrganizationStandaloneVersionList {
    return state.standaloneVersions;
  },

  standaloneBetaVersions(state: ISystemState): OrganizationStandaloneVersionList {
    return state.standaloneBetaVersions;
  },

  featureEnabled(state: ISystemState): FeatureEnabled {
    return (key: string) => {
      return state.settings?.featureFlags.find((feature: FeatureFlagUiDto) => feature.name === key)?.enabled || false;
    };
  },
};

import { render, staticRenderFns } from "./StickToBottom.vue?vue&type=template&id=053dbcd4&scoped=true&"
import script from "./StickToBottom.vue?vue&type=script&lang=ts&"
export * from "./StickToBottom.vue?vue&type=script&lang=ts&"
import style0 from "./StickToBottom.vue?vue&type=style&index=0&id=053dbcd4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "053dbcd4",
  null
  
)

export default component.exports
import { AccountType } from "@/models/AccountType";
import { IRootState } from "@/store/types";
import { Dictionary } from "modules/mcm-vue-components/src/McmErrorPage";

export const ACTION_OVERVIEW_PAGE_SHOW = "overviewPage.show";
export const ACTION_PROFILE_PAGE_SHOW = "profilePage.show";
export const ACTION_DOWNLOADS_PAGE_SHOW = "downloadsPage.show";
export const ACTION_MEMBERS_PAGE_SHOW = "membersPage.show";
export const ACTION_MANAGER_DOWNLOAD_SHOW = "downloadsPage.download.show";
export const ACTION_BETA_ACCESS_PAGE_SHOW = "betaAccessPage.show";

export type CanDoFunction = (state: IRootState) => boolean;

export const AUTHORIZABLE_ACTIONS: Dictionary<AccountType[] | CanDoFunction> = {
  [ACTION_OVERVIEW_PAGE_SHOW]: [AccountType.Owner, AccountType.Admin, AccountType.OutsideOrga],
  [ACTION_DOWNLOADS_PAGE_SHOW]: [AccountType.Owner, AccountType.Admin, AccountType.OutsideOrga],
  [ACTION_PROFILE_PAGE_SHOW]: [], // everyone can see this
  [ACTION_MANAGER_DOWNLOAD_SHOW]: [AccountType.Owner, AccountType.Admin],
  [ACTION_MEMBERS_PAGE_SHOW]: [AccountType.Owner],
  [ACTION_BETA_ACCESS_PAGE_SHOW]: (state: IRootState) => {
    return state?.identity?.hasAdminAccess;
  },
};

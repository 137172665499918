import urls from "@/config/urls";

import Repository from "./repository";
import { getJsonData } from "./helpers";

import { ExternalModulesUiDto } from "@/models/ExternalModulesUiDto";

export interface IPluginsRepository {
  fetchModules(orgaId: string | null): Promise<ExternalModulesUiDto>;
}

class PluginsRepository implements IPluginsRepository {
  public async fetchModules(orgaId: string | null): Promise<ExternalModulesUiDto> {
    const response = await Repository.get(urls.api.plugins(orgaId));

    return getJsonData<ExternalModulesUiDto>(response);
  }
}

export default new PluginsRepository();

import { defineComponent } from "@vue/composition-api";

import { FooterLink, McmErrorPage, NavigationList, TotalErrorMessage } from "../../../modules/mcm-vue-components";
import {
  ACTION_DOWNLOADS_PAGE_SHOW,
  ACTION_OVERVIEW_PAGE_SHOW,
  ACTION_PROFILE_PAGE_SHOW,
  ACTION_MEMBERS_PAGE_SHOW,
  ACTION_BETA_ACCESS_PAGE_SHOW,
} from "@/config/authActions";
import { CanDoMethod } from "@/store/index";
import Snackbar from "@/components/Snackbar.vue";
import RocketLaunch from "../../icons/rocket_launch_black_24dp.svg";

export default defineComponent({
  name: "app",

  components: {
    McmErrorPage,
    Snackbar,
  },

  computed: {
    isAuthenticated(): boolean {
      return this.$store.getters["identity/isAuthenticated"];
    },
    canDo(): CanDoMethod {
      return this.$store.getters["canDo"];
    },
    settingsLoaded(): boolean {
      return this.$store.getters["system/areSettingsLoaded"];
    },
    version(): string {
      return this.$store.getters["system/backendVersion"];
    },
    documentationUrl(): string {
      return this.$store.getters["system/documentationUrl"];
    },

    didOrganizationsLoad(): boolean {
      return this.$store.getters["users/didOrganizationsLoad"];
    },

    error(): TotalErrorMessage | null {
      return this.$store.getters["ui/error"];
    },

    footerLinks(): FooterLink[] {
      return [
        { url: "/imprint/", title: this.$t("components.footerLinks.imprint") },
        { url: "/terms-of-use/", title: this.$t("components.footerLinks.terms") },
        { url: "/privacy/", title: this.$t("components.footerLinks.privacy") },
      ];
    },

    navigationItems(): NavigationList {
      return [
        this.isAuthenticated && this.didOrganizationsLoad
          ? [
              {
                link: { name: "overview" },
                title: this.$t("navigation.items.overview"),
                icon: "home",
                visible: this.canDo(ACTION_OVERVIEW_PAGE_SHOW),
              },
              {
                link: { name: "profile" },
                title: this.$t("navigation.items.profile"),
                icon: "person",
                visible: this.canDo(ACTION_PROFILE_PAGE_SHOW),
              },
              {
                link: { name: "managerDownload" },
                title: this.$t("navigation.items.downloads"),
                icon: "file_download",
                visible: this.canDo(ACTION_DOWNLOADS_PAGE_SHOW),
              },
              {
                link: { name: "members" },
                title: this.$t("navigation.items.members"),
                icon: "group",
                visible: this.canDo(ACTION_MEMBERS_PAGE_SHOW),
              },
            ]
          : [],
        this.canDo(ACTION_BETA_ACCESS_PAGE_SHOW)
          ? [
              {
                link: { name: "betaAccess" },
                title: this.$t("navigation.items.betaAccess"),
                icon: RocketLaunch,
                iconClass: "navi-icon--admin",
              },
            ]
          : [],
        [
          {
            link: this.documentationUrl,
            title: this.$t("navigation.items.documentation"),
            icon: "book",
            external: true,
          },
        ],
      ];
    },
  },
});

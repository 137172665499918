




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class McmButton extends Vue {
  @Prop() public color!: string;
  @Prop() public disabled!: boolean;
  @Prop() public small!: boolean;
  @Prop({ default: null }) public doneText!: string;
  @Prop() public text!: boolean;
  @Prop() public sending!: boolean;
  @Prop() public type!: string;
  public successful!: boolean;

  public data(): object {
    return {
      successful: false,
    };
  }

  public showSuccess() {
    this.successful = true;
    setTimeout(() => {
      this.successful = false;
    }, 2000);
  }
}

import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IUiState } from "./types";

import { PersistentErrorMessage, TotalErrorMessage } from "../../../modules/mcm-vue-components";
import { ExistingOrganizationUiDto } from "@/models/ExistingOrganizationUiDto";

let nextId = 1;

export const createActions = (): ActionTree<IUiState, IRootState> => ({
  async pageRequiresAuth({ commit }, value: boolean): Promise<void> {
    commit("pageRequiresAuth", value);
  },

  async showInfo({ commit }, message: string): Promise<void> {
    commit("addSnackbarError", {
      id: nextId++,
      message,
      timeout: 6000,
      type: "info",
      persistent: false,
    });
  },

  async raiseError({ commit }, message: string): Promise<void> {
    commit("addSnackbarError", {
      id: nextId++,
      message,
      timeout: 6000,
      type: "alert",
      persistent: false,
    });
  },

  async showPersistentError({ commit }, { id, message, action: errorAction }: PersistentErrorMessage): Promise<void> {
    commit("addSnackbarError", {
      id,
      message,
      timeout: null,
      type: "alert",
      persistent: true,
      action: errorAction,
    });
  },

  async hideErrorPage({ commit }): Promise<void> {
    commit("clearError");
  },

  async showErrorPage(
    { commit },
    { code, message, subtitle, title, action: errorAction, type }: TotalErrorMessage
  ): Promise<void> {
    commit("addError", {
      code,
      message,
      subtitle,
      title,
      action: errorAction,
      type,
    });
  },

  async hidePersistentError({ commit }, id: number | string): Promise<void> {
    commit("removeSnackbarError", id);
  },

  async openNavigation({ commit }): Promise<void> {
    commit("changeNavigation", true);
  },

  async closeNavigation({ commit }): Promise<void> {
    commit("changeNavigation", false);
  },

  async selectOrganization({ commit }, value: string | ExistingOrganizationUiDto | null): Promise<void> {
    const orgaId = value ? (typeof value === "string" ? value : value.id) : null;
    commit("selectOrganization", orgaId);
    localStorage.currentOrganizationId = orgaId;
  },

  async hideIntroduction({ commit }): Promise<void> {
    commit("setIntroductionHidden", true);
    localStorage.introductionHidden = true;
  },
});

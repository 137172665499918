var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-page"},[_c('PageHeader',{attrs:{"withOrgaSelector":true}},[_vm._v(_vm._s(_vm.$t("views.members.breadcrumb")))]),_c('mcm-content',[_c('v-container',{staticClass:"users-container",attrs:{"grid-list-xl":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('mcm-can-do',{attrs:{"what":_vm.canDoRule,"alternativeRoute":"profile"}},[_c('mcm-card',{attrs:{"title":_vm.$t('views.members.title'),"noPadding":true,"subtitle":_vm.$t('views.members.titleInfo')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":_vm.handleAddUsersClick}},[_c('v-icon',[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t("views.members.actions.addUser"))+" ")],1),_c('mcm-search-input',{attrs:{"initialValue":_vm.initialSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"note",fn:function(){return [_c('v-icon',[_vm._v("info_outline")]),_vm._v(" "+_vm._s(_vm.$t("views.members.footer.info"))+" ")]},proxy:true}])},[_c('AddUserDialog',{model:{value:(_vm.showAddUserDialog),callback:function ($$v) {_vm.showAddUserDialog=$$v},expression:"showAddUserDialog"}}),_c('EditUserDialog',{attrs:{"user":_vm.editUserDialogUser},model:{value:(_vm.showEditUserDialog),callback:function ($$v) {_vm.showEditUserDialog=$$v},expression:"showEditUserDialog"}}),_c('v-data-table',{staticClass:"elevation-1 clickable-table",attrs:{"hide-default-footer":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"search":_vm.search,"disable-pagination":true},scopedSlots:_vm._u([{key:"item.accountType",fn:function(ref){
var item = ref.item;
return [_c('UserRole',{attrs:{"role":item.accountType}})]}},{key:"item.pending",fn:function(ref){
var item = ref.item;
return [(item.invitation)?_c('strong',[_vm._v(_vm._s(_vm.$t(("views.members.status." + (item.invitation.status)))))]):_vm._e()]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [(item.isEditable)?_c('v-icon',{on:{"click":function($event){return _vm.handleEditItemClick(item)}}},[_vm._v("edit")]):_vm._e(),(item.isRemovable)?_c('WithConfirmation',{attrs:{"maxWidth":700,"submitButton":_vm.$t('views.members.editUserDialog.removeUserDialog.submitButton'),"dialogTitle":_vm.$t('views.members.editUserDialog.removeUserDialog.title'),"dialogDescription":_vm.$t('views.members.editUserDialog.removeUserDialog.description', {
                        email: item.email,
                        name: item.name,
                      }),"isPerforming":_vm.isRemoving},on:{"submit":function($event){return _vm.handleDeleteItemClick(item)}},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("delete")])]}}],null,true),model:{value:(_vm.showRemoveDialog[item.id]),callback:function ($$v) {_vm.$set(_vm.showRemoveDialog, item.id, $$v)},expression:"showRemoveDialog[item.id]"}}):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
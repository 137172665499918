import { Module } from "vuex";
import { IRootState } from "../types";
import { createActions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IUiState } from "./types";
import localStorage from "@/store/localStorage";

export const state: IUiState = {
  error: null,
  introductionHidden: localStorage.introductionHidden,
  navigationOpen: false,
  pageRequiresAuth: false,
  selectedOrganizationId: localStorage.currentOrganizationId,
  snackbars: [],
};

export const ui: Module<IUiState, IRootState> = {
  namespaced: true,

  state,
  getters,
  actions: createActions(),
  mutations,
};

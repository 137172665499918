import HubConnector, { ConnectionConfiguration } from "./HubConnector";
import urls from "@/config/urls";
import { createSingletonFactory } from "@/helpers";
import { Store } from "vuex";
import { IRootState } from "@/store/types";

export const userConnection: ConnectionConfiguration = {
  events: {
    InvitationChanged: "users/invitationChanged",
    OrganizationAssignmentChanged: "users/organizationAssignmentChanged",
    UserChanged: "users/userChanged",
  },
  signals: {},
  loadError: "usersLoadError",
  urlEndpoint: urls.hub.users,
};

export default class UserConnector extends HubConnector {
  constructor(store: Store<IRootState> | null = null) {
    super(userConnection, store);
  }
}

const createUserConnector = createSingletonFactory<UserConnector>(UserConnector);

export { createUserConnector };

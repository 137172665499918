




import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Documentation",

  beforeMount() {
    window.location.replace(this.$store.getters["system/documentationUrl"]);
  },
});

import * as Msal from "@azure/msal-browser";

import localStorage from "@/store/localStorage";
import { SettingsUiDto } from "@/models/SettingsUiDto";

export interface LoginToken {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  accessToken: string;
  adminAccess: boolean;
}

export default class MsalAuthService {
  private app: Msal.PublicClientApplication;
  private scopes: string[] = [];
  private account?: Msal.AccountInfo;
  private token?: LoginToken;
  private settings: SettingsUiDto;

  constructor(settings: SettingsUiDto, redirectUri = `${window.location.origin}/login`) {
    this.app = new Msal.PublicClientApplication({
      auth: {
        knownAuthorities: settings.knownAuthorities,
        authority: settings.signInSignOutAuthority,
        clientId: settings.clientId,
        postLogoutRedirectUri: window.location.origin,
        redirectUri,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
    });
    this.settings = settings;
    this.scopes = settings.scopes;
  }

  /**
   * loginCallback is used on the callback page after login redirect have been called, to process the
   * returned state within location.hash, extract the IDtoken and store it in Msal.UserAgentApplication
   */
  public async processLoginCallback(): Promise<void> {
    return await this.handleMsalPromise();
  }

  public async login({ email, returnPath }: { email?: string; returnPath?: string } = {}): Promise<void> {
    if (returnPath) {
      localStorage.loginReturnPath = returnPath;
    }
    try {
      await this.app.loginRedirect({
        loginHint: email || "",
        scopes: this.scopes,
      });
    } catch (err) {
      if ((err as Msal.AuthError).errorCode === "interaction_in_progress") {
        this.logout();
      } else {
        throw err;
      }
    }
  }

  public passwordReset(): void {
    this.app.loginRedirect({
      authority: this.settings.passwordResetAuthority,
      scopes: this.scopes,
    });
  }

  public logout(): void {
    this.app.logout();
  }

  public getUser(): Msal.AccountInfo | undefined {
    return this.account;
  }

  public async getToken(): Promise<LoginToken | null> {
    if (this.token) {
      return this.token;
    }
    try {
      const account = this.app.getAllAccounts()[0];
      if (!account) {
        return null;
      }
      const response = await this.app.acquireTokenSilent({
        account,
        scopes: this.scopes,
      });
      this.token = {
        accessToken: response.accessToken,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        adminAccess: (account.idTokenClaims as any).adminAccess || false,
      };
      return this.token;
    } catch (err) {
      return null;
    }
  }

  private async handleMsalPromise(): Promise<void> {
    const result = await this.app.handleRedirectPromise();
    this.account = result?.account || this.app.getAllAccounts()[0];
    if (result) {
      this.token = {
        accessToken: result.accessToken,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        adminAccess: (this.account.idTokenClaims as any).adminAccess || false,
      };
      this.redirectAfterLogin();
    }
  }

  private redirectAfterLogin(): void {
    if (localStorage.loginReturnPath) {
      const redirectLocation = localStorage.loginReturnPath;
      localStorage.loginReturnPath = null;
      window.location.href = redirectLocation;
    }
  }
}

export type AuthError = Msal.AuthError;

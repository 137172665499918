import { Module } from "vuex";
import { IRootState } from "../types";
import { createActions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IUsersState } from "./types";
import { UserInOrganizationUiDto } from "@/models/UserInOrganizationUiDto";
import userRepository from "@/repositories/userRepository";
import invitationRepository from "@/repositories/invitationRepository";

export const state: IUsersState = {
  invitations: [],
  invitationsLoading: false,
  invitationsLoadingError: false,

  organizations: [],
  organizationsLoading: false,
  organizationsLoadingError: false,
  organizationsDidLoad: false,

  profile: {} as UserInOrganizationUiDto,
  profileLoading: false,
  profileLoadingError: false,

  updatingProfile: false,

  users: [],
  usersLoading: false,
  usersLoadingError: false,
};

export const users: Module<IUsersState, IRootState> = {
  namespaced: true,

  state,
  getters,
  actions: createActions(userRepository, invitationRepository),
  mutations,
};

import { Module } from "vuex";
import { IRootState } from "../types";
import { createActions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IIdentityState } from "./types";
import MsalAuthService from "@/services/MsalAuthService";

export const state: IIdentityState = {
  isAuthenticated: false,
  user: null,

  initialized: false,
  hasAdminAccess: false,
};

export const identity: Module<IIdentityState, IRootState> = {
  namespaced: true,

  state,
  getters,
  actions: createActions(MsalAuthService),
  mutations,
};

import urls from "@/config/urls";

import Repository from "./repository";
import { getJsonData } from "./helpers";

import { UserInOrganizationUiDto } from "@/models/UserInOrganizationUiDto";
import { AssignedOrganizationUiDto } from "@/models/AssignedOrganizationUiDto";
import { NewInvitationUiDto } from "@/models/NewInvitationUiDto";
import { OrganizationUserChangesUiDto } from "@/models/OrganizationUserChangesUiDto";
import { InvitationChangesUiDto } from "@/models/InvitationChangesUiDto";

export interface IUserRepository {
  fetchOrganizations(): Promise<AssignedOrganizationUiDto[]>;
  fetchProfile(): Promise<UserInOrganizationUiDto>;
  fetchUsersOfOrganization(orgaId: string): Promise<UserInOrganizationUiDto[]>;
  inviteUser(orgaId: string, invitation: NewInvitationUiDto): Promise<boolean>;
  removeInvitation(orgaId: string, invitationId: string): Promise<boolean>;
  removeUser(orgaId: string, userId: string): Promise<boolean>;
  renameProfile(name: string): Promise<UserInOrganizationUiDto>;
  updateInvitation(invitationId: string, invitation: InvitationChangesUiDto): Promise<UserInOrganizationUiDto>;
  updateUser(orgaId: string, userId: string, user: OrganizationUserChangesUiDto): Promise<UserInOrganizationUiDto>;
}

class UserRepository implements IUserRepository {
  public async fetchOrganizations(): Promise<AssignedOrganizationUiDto[]> {
    const response = await Repository.get(urls.api.organizations.all);

    return getJsonData(response) as AssignedOrganizationUiDto[];
  }

  public async fetchProfile(): Promise<UserInOrganizationUiDto> {
    const response = await Repository.get(urls.api.users.profile);

    const user = getJsonData(response) as UserInOrganizationUiDto;
    return user || ({ name: "" } as UserInOrganizationUiDto);
  }

  public async inviteUser(orgaId: string, invitation: NewInvitationUiDto): Promise<boolean> {
    // There is no response coming back
    await Repository.post(urls.api.invitations.create(orgaId), invitation);

    return true;
  }

  public async renameProfile(name: string): Promise<UserInOrganizationUiDto> {
    const response = await Repository.patch(urls.api.users.profile, { name });

    return getJsonData(response) as UserInOrganizationUiDto;
  }

  public async fetchUsersOfOrganization(orgaId: string): Promise<UserInOrganizationUiDto[]> {
    const response = await Repository.get(urls.api.organizations.users(orgaId));

    return getJsonData(response) as UserInOrganizationUiDto[];
  }

  public async updateInvitation(
    invitationId: string,
    invitation: InvitationChangesUiDto
  ): Promise<UserInOrganizationUiDto> {
    const response = await Repository.patch(urls.api.invitations.edit(invitationId), invitation);

    return getJsonData(response) as UserInOrganizationUiDto;
  }

  public async removeInvitation(orgaId: string, invitationId: string): Promise<boolean> {
    await Repository.delete(urls.api.invitations.remove(orgaId, invitationId));

    return true;
  }

  public async updateUser(
    orgaId: string,
    userId: string,
    user: OrganizationUserChangesUiDto
  ): Promise<UserInOrganizationUiDto> {
    const response = await Repository.patch(urls.api.organizations.patchUser(orgaId, userId), user);

    return getJsonData(response) as UserInOrganizationUiDto;
  }

  public async removeUser(orgaId: string, userId: string): Promise<boolean> {
    await Repository.delete(urls.api.organizations.removeUser(orgaId, userId));

    return true;
  }
}

export default new UserRepository();

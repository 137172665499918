import { MutationTree } from "vuex";

import { IUsersState } from "./types";
import { UserInOrganizationUiDto } from "@/models/UserInOrganizationUiDto";
import { InvitationUiDto } from "@/models/InvitationUiDto";
import { AssignedOrganizationUiDto } from "@/models/AssignedOrganizationUiDto";

export const mutations: MutationTree<IUsersState> = {
  updateProfile(state: IUsersState, profile: UserInOrganizationUiDto): void {
    state.profile = profile;
  },

  setUpdatingProfile(state: IUsersState, value: boolean): void {
    state.updatingProfile = value;
  },

  setProfileError(state: IUsersState, value: boolean): void {
    state.profileLoadingError = value;
  },

  setLoadingProfile(state: IUsersState, value: boolean): void {
    state.profileLoading = value;
  },

  updateInvitations(state: IUsersState, invitations: InvitationUiDto[]): void {
    state.invitations = invitations;
  },

  removeInvitation(state: IUsersState, invitationId: string): void {
    state.invitations = state.invitations.filter(
      (invitation: InvitationUiDto) => invitation.invitationId !== invitationId
    );
    state.users = state.users.filter((user: UserInOrganizationUiDto) => !(user.id === invitationId && user.invitation));
  },

  updateInvitation(state: IUsersState, invitation: InvitationUiDto): void {
    state.invitations = state.invitations.map((inv: InvitationUiDto) =>
      inv.invitationId === invitation.invitationId ? invitation : inv
    );
    state.users = state.users.map((user: UserInOrganizationUiDto) => {
      if (user.id === invitation.invitationId && user.invitation) {
        return {
          ...user,
          accountType: invitation.accountType,
          email: invitation.email,
          name: invitation.name,
          invitationStatus: invitation.status,
        };
      }
      return user;
    });
  },

  setInvitationsLoadingError(state: IUsersState, value: boolean): void {
    state.invitationsLoadingError = value;
  },

  setInvitationsLoading(state: IUsersState, value: boolean): void {
    state.invitationsLoading = value;
  },

  setOrganizations(state: IUsersState, orgas: AssignedOrganizationUiDto[]): void {
    state.organizations = orgas;
  },

  updateAssignedOrga(state: IUsersState, updatedOrga: AssignedOrganizationUiDto): void {
    state.organizations = state.organizations.map((orga: AssignedOrganizationUiDto) =>
      updatedOrga.id === orga.id ? updatedOrga : orga
    );
  },

  setOrganizationsLoadingError(state: IUsersState, value: boolean): void {
    state.organizationsLoadingError = value;
  },

  setOrganizationsLoading(state: IUsersState, value: boolean): void {
    state.organizationsLoading = value;
  },

  setOrganizationsDidLoad(state: IUsersState, value: boolean): void {
    state.organizationsDidLoad = value;
  },

  setUsers(state: IUsersState, users: UserInOrganizationUiDto[]): void {
    state.users = users;
  },

  addUserInOrganization(state: IUsersState, user: UserInOrganizationUiDto): void {
    state.users = state.users.concat(user);
    // also add the associated invitation
    if (user.invitation) {
      state.invitations = state.invitations.concat(user.invitation);
    }
  },

  updateUserInOrganization(state: IUsersState, user: UserInOrganizationUiDto): void {
    state.users = state.users.map((u: UserInOrganizationUiDto) => (u.id === user.id ? user : u));
    // also update the associated invitation
    if (user.invitation) {
      state.invitations = state.invitations.map((inv: InvitationUiDto) =>
        inv.invitationId === (user.invitation && user.invitation.invitationId) ? user.invitation : inv
      ) as InvitationUiDto[];
    }
  },

  removeUserFromOrganization(state: IUsersState, userId: string): void {
    const user = state.users.find((u: UserInOrganizationUiDto) => u.id === userId);
    state.users = state.users.filter((u: UserInOrganizationUiDto) => u.id !== userId);
    // also update the associated invitation
    if (user && user.invitation) {
      state.invitations = state.invitations.filter(
        (inv: InvitationUiDto) => inv.invitationId !== (user.invitation && user.invitation.invitationId)
      );
    }
  },

  removeUserFromOrganizationViaSignal(state: IUsersState, user: UserInOrganizationUiDto): void {
    state.users = state.users.filter((u: UserInOrganizationUiDto) => u.id !== user.id);
    // also update the associated invitation
    if (user && user.invitation) {
      state.invitations = state.invitations.filter(
        (inv: InvitationUiDto) => inv.invitationId !== (user.invitation && user.invitation.invitationId)
      );
    }
  },

  setUsersLoadingError(state: IUsersState, value: boolean): void {
    state.usersLoadingError = value;
  },

  setUsersLoading(state: IUsersState, value: boolean): void {
    state.usersLoading = value;
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as signalR from "@microsoft/signalr";

import realStore from "@/store/index";
import { ChangeAction } from "@/models/ChangeAction";
import Vue from "vue";
import { tokens } from "@/repositories/repository";
import { Dictionary } from "@/types";
import { IRootState } from "@/store/types";
import { Store } from "vuex";

export interface IConnector {
  connect(): Promise<void>;
  disconnect(): Promise<void>;
}

export interface ConnectionConfiguration {
  events: Dictionary<string>;
  loadError: string;
  signals?: Dictionary<string>;
  urlEndpoint: string;
}

type ConnectionConfigurationSet = ConnectionConfiguration & {
  // Those will be defaulted
  signals: Dictionary<string>;
};

type EventHandler = (...args: any[]) => void;
interface RegisteredEventHandler {
  event: string;
  handler: EventHandler;
}

export default class HubConnector implements IConnector {
  protected store: Store<IRootState>;
  private config: ConnectionConfigurationSet;
  private connection: signalR.HubConnection;
  private shouldBeRunning = false;
  private stopLastConnection: number | null = null;
  private registeredEventHandlers: Array<RegisteredEventHandler> = [];

  constructor(config: ConnectionConfiguration, store: Store<IRootState> | null) {
    this.store = store || realStore;
    this.config = {
      signals: {},
      ...config,
    };
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(config.urlEndpoint, {
        accessTokenFactory: () => {
          return tokens.authToken || "";
        },
      })
      .withAutomaticReconnect()
      .build();
  }

  public async connect(): Promise<void> {
    this.shouldBeRunning = true;
    if (this.stopLastConnection) {
      window.clearTimeout(this.stopLastConnection);
      this.stopLastConnection = null;
    }
    if (this.connection.state !== "Disconnected") {
      // We are already and still connected
      return;
    }
    // better reload data, since it could have gotton stale
    await this.startConnection();
    this.connection.onclose(this.handleCloseConnection.bind(this));
    Object.keys(this.config.events).forEach((event: string) => {
      const handler: EventHandler = (item, changeAction) =>
        this.handleEvent(this.config.events[event], item, changeAction);
      this.connection.on(event, handler);
      this.registeredEventHandlers.push({ event, handler });
    });
    Object.keys(this.config.signals).forEach((signal: string) => {
      const handler: EventHandler = (...args) => this.handleSignal(signal, this.config.signals[signal], args);
      this.connection.on(signal, handler);
      this.registeredEventHandlers.push({ event: signal, handler });
    });
    // Inform about reconnecting attempt
    this.connection.onreconnecting(this.onReconnecting.bind(this));
    // Reload needed data
    this.connection.onreconnected(this.handleReconnected.bind(this));
    this.store.dispatch("ui/hidePersistentError", "websocketDisconnect");
  }

  public async disconnect(): Promise<void> {
    this.store.dispatch("ui/hidePersistentError", this.config.loadError);
    if (!this.shouldBeRunning) {
      return;
    }
    // Disconnect after a timeout to give the next page to pickup this connection if it needs it
    this.stopLastConnection = window.setTimeout(() => {
      this.stopConnection();
    }, 300);
  }

  private handleReconnected(): void {
    this.store.dispatch("ui/hideReconnectingIndicator");
    this.onConnectionStart();
  }

  /**
   * Override this method if you need to do something before a connection is started.
   */
  protected async onConnectionStart(): Promise<void> {} // eslint-disable-line @typescript-eslint/no-empty-function

  protected async onRefresh(): Promise<void> {
    throw new Error("You defined a refreshEventHandler. You also need to overload onRefresh!");
  }

  private handleCloseConnection(err: Error | undefined) {
    if (this.shouldBeRunning || err) {
      this.showReconnectError();
    }
  }

  private showReconnectError(): void {
    // We show this on the next tick so the user does not see this message on a page reload
    Vue.nextTick(() => {
      this.store.dispatch("ui/showPersistentError", {
        id: "websocketDisconnect",
        message: "errors.websocketDisconnected",
      });
    });
  }

  private async startConnection(): Promise<void> {
    const messageName = this.config.loadError;
    this.store.dispatch("ui/hidePersistentError", messageName);
    this.store.dispatch("ui/hidePersistentError", "websocketDisconnect");
    try {
      await this.onConnectionStart();
    } catch (e) {
      this.store.dispatch("ui/showPersistentError", {
        id: messageName,
        message: `errors.${messageName}`,
      });
      return;
    }
    this.doStartConnection();
  }

  private async doStartConnection(): Promise<void> {
    try {
      await this.connection.start();
    } catch (e) {
      console.error(e);
      this.showReconnectError();
    }
  }

  private onReconnecting(): void {
    this.store.dispatch("ui/showReconnectingIndicator");
  }

  private async stopConnection(): Promise<void> {
    this.stopLastConnection = null;
    this.shouldBeRunning = false;
    this.registeredEventHandlers.forEach(({ event, handler }: RegisteredEventHandler) => {
      this.connection.off(event, handler);
    });
    await this.connection.stop();
  }

  private handleSignal(signal: string, methodName: string, args: any[]): void {
    if ((this as any)[methodName]) {
      (this as any)[methodName](...args);
    } else {
      throw new Error(`Connector does not implement ${methodName} handler for signal ${signal}.`);
    }
  }

  private handleEvent(dispatchHandler: string, item: any, changeAction: ChangeAction): void {
    this.store.dispatch(dispatchHandler, { item, changeAction });
  }
}





























































import { defineComponent } from "@vue/composition-api";
import { TranslateResult } from "vue-i18n";

import { McmButton, validations } from "../../modules/mcm-vue-components";
import { ExistingOrganizationUiDto } from "@/models/ExistingOrganizationUiDto";
import { Dictionary } from "@/types";
import ValidationError from "../repositories/ValidationError";

const FORBIDDEN_ORGA_NAME_CHARS = "<>;&%".split("");
export function invalidChars(errorMsg: TranslateResult) {
  return (value: string = "") =>
    !(value && FORBIDDEN_ORGA_NAME_CHARS.find((char: string) => value.indexOf(char) !== -1)) || errorMsg;
}

const HTTPS_REGEX = /^https:\/\/.+/;
export function httpsUri(errorMsg: TranslateResult) {
  return (value: string = "") => !value || HTTPS_REGEX.test(value) || errorMsg;
}

export default defineComponent({
  name: "OrganizationCard",

  props: {
    isCreation: { type: Boolean, default: false },
  },

  data() {
    return {
      backendErrors: {} as Dictionary<string>,
      isSaving: false,
      showChangeMessage: false,
      writable: {} as ExistingOrganizationUiDto,
    };
  },

  mounted(): void {
    this.handleCurrentOrganizationChange();
  },

  computed: {
    loadedOrganization(): ExistingOrganizationUiDto | null {
      return this.$store.getters["organizations/organization"];
    },
    currentOrganizationId(): string | null {
      return this.$store.getters["currentOrganizationId"];
    },
    organizationLoading(): boolean {
      return this.$store.getters["organizations/isLoading"];
    },

    organization(): ExistingOrganizationUiDto | null {
      return this.isCreation ? ({} as ExistingOrganizationUiDto) : this.loadedOrganization;
    },

    isExistingOrganization(): boolean {
      return !!(this.organization && this.organization.id);
    },

    validations(): object {
      return {
        name: [
          validations.required(this.$t("components.orgaCard.errors.nameRequired")),
          validations.maxLength(120, this.$t("components.orgaCard.errors.maxLength", { count: 120 })),
          invalidChars(
            this.$t("components.orgaCard.errors.invalidChars", { forbiddenChars: FORBIDDEN_ORGA_NAME_CHARS.join(" ") })
          ),
        ],
        redirectUri: [
          validations.required(this.$t("components.orgaCard.errors.redirectUriRequired")),
          httpsUri(this.$t("components.orgaCard.errors.redirectUriHttps")),
        ],
      };
    },

    isPristine(): boolean {
      return (
        (this.writable.name || "") === (this.organization?.name || "") &&
        (this.writable.redirectUri || "") === (this.organization?.redirectUri || "")
      );
    },
    $form(): HTMLFormElement {
      return this.$refs.form as HTMLFormElement;
    },
  },

  methods: {
    selectOrganization(orgaId: string | null): void {
      this.$store.dispatch("ui/selectOrganization", orgaId);
    },

    createOrUpdate(orga: ExistingOrganizationUiDto): ExistingOrganizationUiDto {
      return this.$store.dispatch("organizations/createOrUpdate", orga) as unknown as ExistingOrganizationUiDto;
    },

    fetchOrganization(orgaId: string | null): void {
      this.$store.dispatch("organizations/fetchOrganization", orgaId);
    },

    fetchOrganizations(): void {
      this.$store.dispatch("users/fetchOrganizations");
    },

    async handleSubmit(): Promise<void> {
      if (!this.$form.validate()) {
        return;
      }
      this.isSaving = true;
      this.backendErrors = {};
      const redirectUriBefore = this.organization?.redirectUri;
      try {
        const orga = await this.createOrUpdate(this.writable);
        await this.fetchOrganizations();
        if (this.isCreation) {
          this.selectOrganization(orga.id);
          this.$router.push({ name: "managerDownload" });
        }
        (this.$refs.submitButton as McmButton).showSuccess();
        if (redirectUriBefore !== this.organization?.redirectUri) {
          this.showChangeMessage = true;
        }
      } catch (err) {
        if (err instanceof ValidationError) {
          this.backendErrors = err.validationErrors;
        }
      }
      this.isSaving = false;
    },

    handleCurrentOrganizationChange(): void {
      if (this.currentOrganizationId) {
        this.fetchOrganization(this.currentOrganizationId);
      }
    },

    handleReset(): void {
      this.backendErrors = {};
      this.$form.resetValidation();
      this.writable = {
        ...(this.organization || ({} as ExistingOrganizationUiDto)),
      };
    },
  },

  watch: {
    currentOrganizationId() {
      this.handleCurrentOrganizationChange();
    },
    organization() {
      this.handleReset();
    },
  },
});













import { defineComponent, PropType } from "@vue/composition-api";

import { AccountType } from "../models/AccountType";

export default defineComponent({
  name: "UserRole",

  props: {
    role: { type: Number as PropType<AccountType> },
    xSmall: { type: Boolean },
  },

  computed: {
    color(): string {
      switch (this.role) {
        case AccountType.Admin:
          return "#45169d";
        case AccountType.Moderator:
          return "#ff9003";
        case AccountType.Owner:
          return "#2bb600";
        default:
          return "";
      }
    },
  },
});

import type { Location } from "vue-router";
import { Vue, Component, Prop } from "vue-property-decorator";

export interface TabItem {
  title: string;
  to?: string | Location;
  value?: string | number;
}

@Component
export default class McmHeaderTabs extends Vue {
  @Prop() public items!: TabItem[];
  @Prop() public model!: number;
}

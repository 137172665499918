import { onBeforeUnmount, onMounted } from "@vue/composition-api";

import UserConnector, { createUserConnector } from "../repositories/UserConnector";

export default function connectUserHub(): void {
  const userConnector: UserConnector = createUserConnector();

  onMounted(() => {
    userConnector.connect();
  });

  onBeforeUnmount(() => {
    userConnector.disconnect();
  });
}

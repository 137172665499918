



























































import { defineComponent, PropType } from "@vue/composition-api";

import { ExternalBundleUiDto } from "@/models/ExternalBundleUiDto";
import urls from "@/config/urls";

export default defineComponent({
  name: "PluginsDownloadCard",

  props: {
    externalModules: { type: Array as PropType<ExternalBundleUiDto[]> },
    extronUrl: { type: String, default: "" },
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      licenseAgreements: {} as Record<string, boolean>,
    };
  },

  computed: {
    documentationUrl(): string {
      return this.$store.getters["system/documentationUrl"];
    },

    releaseNotes(): String {
      return urls.docs.releaseNotes(this.documentationUrl);
    },
  },

  methods: {
    handleChange(pkg: ExternalBundleUiDto, value: boolean) {
      this.licenseAgreements = {
        ...this.licenseAgreements,
        [pkg.name]: value,
      };
    },

    startModuleDownload(module: ExternalBundleUiDto) {
      if (!module) {
        return false;
      }
      window.open(module.url, "_blank");
    },
  },
});

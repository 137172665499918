
























import { defineComponent } from "@vue/composition-api";

import { SnackbarView } from "@/store/ui/types";

export default defineComponent({
  name: "Snackbar",

  computed: {
    snack(): SnackbarView | undefined {
      return this.$store.getters["ui/currentSnack"];
    },
  },

  methods: {
    hidePersistentError(error: String | Number): void {
      this.$store.dispatch("ui/hidePersistentError", error);
    },

    closeMessage(snack: SnackbarView): void {
      this.hidePersistentError(snack.id);
    },
  },
});































import { Component, Vue, Prop } from "vue-property-decorator";

import Fragment from "./Fragment.vue";
import McmErrorPage from "./McmErrorPage.vue";

@Component({
  components: {
    Fragment,
    McmErrorPage
  }
})
export default class McmCanDo extends Vue {
  @Prop() public what!: string;
  @Prop() public alternativeRoute!: string | null;

  public get showSlot(): boolean {
    return (this as any).$store.getters.canDo(this.what);
  }

  public async relogin(): Promise<void> {
    const store = (this as any).$store;
    await store.dispatch("identity/signOut", store.getters["system/settings"]);
  }
}

// https://medium.com/canariasjs/vue-api-calls-in-a-smart-way-8d521812c322

import axios, { AxiosResponse } from "axios";

import store from "@/store/index";
import ValidationError from "./ValidationError";

import router from "../router/index";
import { RequestError } from "../../modules/mcm-vue-components";

export const tokens = {
  authToken: "",
};

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    if (tokens.authToken) {
      config.headers.Authorization = `Bearer ${tokens.authToken}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
);

const handleSuccess = (response: AxiosResponse): AxiosResponse => response;

const handleError = async (error: { response: AxiosResponse }): Promise<void> => {
  // If the page is only visible to authenticated users we show a unauthorized error message
  // else we just ignore it as long as the user stays on that page.
  if (error.response.status === 401 && store.getters["ui/isPageRequiringAuth"]) {
    await store.dispatch("identity/markAsLoggedOut");
    router.push({ name: "loginCallback" });
  } else if (error.response.status === 422) {
    throw new ValidationError(error as unknown as Error);
  } else if (typeof error.response.data.detail === "string") {
    throw new RequestError<string>(error.response.data.type, error.response.data.detail);
  }
  throw error;
};

instance.interceptors.response.use(handleSuccess, handleError);

export default instance;

export { handleError, handleSuccess };

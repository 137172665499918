import { Component, Prop, Vue } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";

import McmLogo from "./McmLogo.vue";
import { VueConstructor } from "vue";

export type NavigationList = NavigationItem[][];

export interface NavigationItem {
  link: string | object;
  title: string | TranslateResult;
  icon: string | VueConstructor<Vue>;
  iconClass?: string;
  visible?: boolean;
  external?: boolean;
}

@Component({
  components: {
    McmLogo,
  },
})
export default class McmNavigation extends Vue {
  @Prop() public width!: number;
  @Prop({ default: 1000 }) public permanentWidth!: number;
  @Prop({ default: [] }) public items!: NavigationList;
  public windowWidth: number = window.innerWidth;

  public mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.updateWindowWidth);
    });
  }

  public beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  }

  public updateWindowWidth(): void {
    this.windowWidth = window.innerWidth;
  }

  public get visibleItems(): NavigationList {
    return this.items
      .map((list: NavigationItem[]) => list.filter((item: NavigationItem) => item.visible !== false))
      .filter((list: NavigationItem[]) => list.length > 0);
  }

  public get navigationOpen(): boolean {
    return (this as any).$store.getters["ui/isNavigationOpen"];
  }

  public get darkTheme(): boolean {
    return this.$vuetify.theme.dark;
  }

  public get isPermanent(): boolean {
    return this.windowWidth >= this.permanentWidth;
  }

  public data(): object {
    return {};
  }

  public updateValue(value: boolean) {
    if (value) {
      this.openNavigation();
    } else {
      this.closeNavigation();
    }
  }

  private openNavigation(): void {
    (this as any).$store.dispatch("ui/openNavigation");
  }

  private closeNavigation(): void {
    (this as any).$store.dispatch("ui/closeNavigation");
  }
}

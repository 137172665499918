/* eslint-disable @typescript-eslint/no-var-requires */
/**
 * These icons can be used together with all icons from https://material.io/tools/icons.
 *
 * @example
 *
 * <v-icon>dns</v-icon>
 *
 */
export default {
  // rocket_launch: { Component: require("../icons/rocket_launch_black_24dp.svg") },
};

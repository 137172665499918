













import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class McmContent extends Vue {
  @Prop() public singleCard!: boolean;
}

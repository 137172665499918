import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { ISystemState } from "./types";
import { SettingsUiDto } from "@/models/SettingsUiDto";

import { ISystemRepository } from "@/repositories/systemRepository";

export const createActions = (systemRepository: ISystemRepository): ActionTree<ISystemState, IRootState> => ({
  async fetchManagerVersions({ commit }, { orgaId, internal }: { orgaId: string; internal: boolean }): Promise<void> {
    commit("setManagerVersionsLoading", true);
    try {
      const { versions, betaVersions } = await systemRepository.fetchManagerVersions(orgaId, internal);
      commit("setManagerVersions", { orgaId, versions, betaVersions });
    } catch (err) {
      // Nothing to do
    }
    commit("setManagerVersionsLoading", false);
  },

  async fetchStandaloneVersions({ commit }, { orgaId }: { orgaId: string }): Promise<void> {
    commit("setStandaloneVersionsLoading", true);
    try {
      const { versions, betaVersions } = await systemRepository.fetchStandaloneVersions(orgaId);
      commit("setStandaloneVersions", { orgaId, versions, betaVersions });
    } catch (err) {
      // Nothing to do
    }
    commit("setStandaloneVersionsLoading", false);
  },

  fetchSettings({ commit, state }): Promise<SettingsUiDto> {
    if (state.settingsLoadPromise) {
      // We only need the settings once.
      return state.settingsLoadPromise;
    }
    commit("setLoadingSettings", true);
    const promise = new Promise<SettingsUiDto>((resolve, reject) => {
      systemRepository
        .fetchSettings()
        .then((settings) => {
          commit("updateSettings", settings);
          commit("setLoadingSettings", false);
          resolve(settings);
        })
        .catch(reject);
    });
    commit("rememberLoadPromise", promise);
    return promise;
  },
});

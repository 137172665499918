






































































import { defineComponent } from "@vue/composition-api";

import AddUserDialog from "./MembersPage/AddUserDialog.vue";
import EditUserDialog from "./MembersPage/EditUserDialog.vue";
import PageHeader from "@/components/PageHeader.vue";
import UserRole from "@/components/UserRole.vue";
import WithConfirmation from "@/components/WithConfirmation.vue";

import { UserInOrganizationUiDto } from "../models/UserInOrganizationUiDto";
import { ACTION_MEMBERS_PAGE_SHOW } from "@/config/authActions";
import setPageTitle from "@/compositions/setPageTitle";
import connectUserHub from "@/compositions/connectUserHub";

export default defineComponent({
  name: "MembersPage",

  components: {
    AddUserDialog,
    EditUserDialog,
    PageHeader,
    UserRole,
    WithConfirmation,
  },

  data() {
    const query = this.$route.query;

    return {
      editUserDialogUser: null as UserInOrganizationUiDto | null,

      loading: false,
      showAddUserDialog: false,
      showEditUserDialog: false,
      showRemoveDialog: {} as Record<string, boolean>,
      isRemoving: false,

      initialSearch: query.search || "",
      search: query.search || "",
    };
  },

  setup() {
    setPageTitle("views.members.pageTitle");
    connectUserHub();
  },

  mounted() {
    this.handleCurrentOrganizationChange();
  },

  computed: {
    canDoRule(): string {
      return ACTION_MEMBERS_PAGE_SHOW;
    },

    headers(): object[] {
      return [
        {
          text: this.$t("views.members.headers.name"),
          value: "name",
          class: "qa-header-name",
        },
        {
          text: this.$t("views.members.headers.accountType"),
          value: "accountType",
          class: "qa-header-account-type",
        },
        {
          text: this.$t("views.members.headers.email"),
          value: "email",
          class: "qa-header-email",
        },
        {
          text: "",
          class: "qa-header-pending",
          value: "pending",
          sortable: false,
        },
        {
          text: this.$t("views.members.headers.edit"),
          value: "edit",
          class: "qa-header-edit",
          sortable: false,
        },
      ];
    },

    currentOrganizationId(): string {
      return this.$store.getters["currentOrganizationId"];
    },
    users(): UserInOrganizationUiDto[] {
      return this.$store.getters["users/users"];
    },
  },

  methods: {
    fetchUserList(orgaId: string): any {
      return this.$store.dispatch("users/fetchUserList", orgaId);
    },

    removeInvitation(params: { orgaId: string; invitationId: string }): any {
      return this.$store.dispatch("users/removeInvitation", params);
    },

    handleEditItemClick(user: UserInOrganizationUiDto): void {
      this.showEditUserDialog = true;
      this.editUserDialogUser = user;
    },

    async handleDeleteItemClick(user: UserInOrganizationUiDto): Promise<void> {
      this.isRemoving = true;
      await this.removeInvitation({ orgaId: this.currentOrganizationId, invitationId: user.id });
      this.isRemoving = false;
      this.showRemoveDialog[user.id] = false;
    },

    handleAddUsersClick(): void {
      this.showAddUserDialog = true;
    },

    handleCurrentOrganizationChange(): void {
      if (this.currentOrganizationId) {
        this.fetchUserList(this.currentOrganizationId);
      }
    },
  },

  watch: {
    currentOrganizationId() {
      this.handleCurrentOrganizationChange();
    },
  },
});













import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "RadioWithInfo",

  props: {
    value: { type: Number },
    label: { type: String },
    info: { type: String },
    isCurrent: { type: Boolean },
  },
});

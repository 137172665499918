




















































import { defineComponent } from "@vue/composition-api";

import UserInvitationDialog from "./UserInvitationDialog.vue";
import RadioWithInfo from "@/components/RadioWithInfo.vue";
import UserRole from "@/components/UserRole.vue";
import { Dictionary } from "@/types";
import { validations, McmButton } from "../../../modules/mcm-vue-components";
import ValidationError from "@/repositories/ValidationError";
import { AccountType } from "@/models/AccountType";
import { NewInvitationUiDto } from "@/models/NewInvitationUiDto";
import { ExistingOrganizationUiDto } from "../../models/ExistingOrganizationUiDto";

export default defineComponent({
  name: "AddUserDialog",

  components: {
    RadioWithInfo,
    UserInvitationDialog,
    UserRole,
  },

  props: {
    value: { type: Boolean },
  },

  data() {
    return {
      backendErrors: {} as Dictionary<string>,
      isSaving: false,
      showConfirmation: false,
      email: "",
      name: "",
      accountType: AccountType.Moderator,
    };
  },

  async mounted() {
    const orgaId = this.currentOrganizationId as string;
    this.fetchUserList(orgaId);
    await this.fetchOrganization(orgaId);
  },

  computed: {
    organization(): ExistingOrganizationUiDto {
      return this.$store.getters["organizations/organization"];
    },
    currentOrganizationId(): string | null {
      return this.$store.getters["currentOrganizationId"];
    },

    validations(): object {
      return {
        email: [
          validations.required(this.$t("views.members.addUserDialog.errors.emailRequired")),
          validations.email(this.$t("views.members.addUserDialog.errors.emailInvalid")),
        ],
        name: [validations.required(this.$t("views.members.addUserDialog.errors.nameRequired"))],
      };
    },

    isChanged(): boolean {
      return this.email !== "" || this.name !== "";
    },
  },

  methods: {
    raiseError(error: string): any {
      return this.$store.dispatch("ui/raiseError", error);
    },
    showInfo(info: string): any {
      return this.$store.dispatch("ui/showInfo", info);
    },

    fetchOrganization(orgaId: string): any {
      return this.$store.dispatch("organizations/fetchOrganization", orgaId);
    },
    fetchUserList(orgaId: string): any {
      return this.$store.dispatch("users/fetchUserList", orgaId);
    },

    inviteUser(params: { orgaId: string; invitation: NewInvitationUiDto }): any {
      return this.$store.dispatch("users/inviteUser", params);
    },

    async handleSubmit(): Promise<void> {
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.isSaving = true;
      this.backendErrors = {};
      try {
        const orgaId = this.currentOrganizationId as string;
        await this.inviteUser({
          orgaId,
          invitation: {
            name: this.name,
            email: this.email,
            accountType: this.accountType,
          },
        });
        this.fetchUserList(orgaId);
        await this.fetchOrganization(orgaId);
        (this.$refs.submitButton as McmButton).showSuccess();
        this.showConfirmation = true;
      } catch (err) {
        if (err instanceof ValidationError) {
          this.backendErrors = err.validationErrors;
        } else {
          this.raiseError("errors.requestFailed");
        }
      }
      this.isSaving = false;
    },

    handleClose(): void {
      this.showConfirmation = false;
      this.$emit("input", false);
    },

    handleOpen(): void {
      if (this.value) {
        this.handleReset();
      }
    },

    handleReset(): void {
      if (this.$refs.form) {
        (this.$refs.form as HTMLFormElement).resetValidation();
      }
      this.email = "";
      this.name = "";
      this.backendErrors = {};
    },
  },

  watch: {
    value() {
      this.handleOpen();
    },
  },
});

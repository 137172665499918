























import { defineComponent, PropType } from "@vue/composition-api";

import UserRole from "@/components/UserRole.vue";
import { InvitationUiDto } from "@/models/InvitationUiDto";

export default defineComponent({
  name: "InvitationItem",

  components: {
    UserRole,
  },

  props: {
    invitation: { type: Object as PropType<InvitationUiDto>, required: true },
  },

  data() {
    return {
      isAccepting: false,
      isRejecting: false,
    };
  },

  computed: {
    isSending(): boolean {
      return this.isAccepting || this.isRejecting;
    },
  },

  methods: {
    acceptInvitation(invitation: InvitationUiDto): Promise<void> {
      return this.$store.dispatch("users/acceptInvitation", invitation);
    },
    rejectInvitation(invitation: InvitationUiDto): Promise<void> {
      return this.$store.dispatch("users/rejectInvitation", invitation);
    },
    fetchOrganizations(): Promise<void> {
      return this.$store.dispatch("users/fetchOrganizations");
    },

    async handleAccept(): Promise<void> {
      this.isAccepting = true;
      await this.acceptInvitation(this.invitation);
      await this.fetchOrganizations();
      this.isAccepting = false;
    },

    async handleReject(): Promise<void> {
      this.isRejecting = true;
      await this.rejectInvitation(this.invitation);
      await this.fetchOrganizations();
      this.isRejecting = false;
    },
  },
});

import { AxiosResponse } from "axios";

export function getJsonData<T>(result: AxiosResponse<T>): T {
  if (result.headers["content-type"].indexOf("application/json") === -1) {
    throw new Error(`Result for ${result.config.url} is not in JSON format.`);
  }
  return result.data;
}

export class RequestError<T> {
  public data: T;
  public type: string;

  constructor(type: string, data: T) {
    this.type = type;
    this.data = data;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function getJsonError<T>(error: any): RequestError<T> {
  if (!error.response || !error.response.data) {
    return error;
  }
  return new RequestError<T>(error.response.data.type, JSON.parse(error.response.data.detail || "{}"));
}

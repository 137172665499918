






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class McmCopyIcon extends Vue {
  @Prop() public success!: boolean;

  public get canCopyPaste(): boolean {
    return !!(window.navigator && window.navigator.clipboard);
  }
}

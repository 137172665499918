import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IUsersState } from "./types";

import { IUserRepository } from "@/repositories/userRepository";
import { IInvitationRepository } from "@/repositories/invitationRepository";
import { InvitationUiDto } from "@/models/InvitationUiDto";
import { NewInvitationUiDto } from "@/models/NewInvitationUiDto";
import { OrganizationUserChangesUiDto } from "@/models/OrganizationUserChangesUiDto";
import { InvitationChangesUiDto } from "@/models/InvitationChangesUiDto";
import { UserInOrganizationUiDto } from "@/models/UserInOrganizationUiDto";
import { ChangeAction } from "@/models/ChangeAction";
import { AssignedOrganizationUiDto } from "@/models/AssignedOrganizationUiDto";

export const createActions = (
  userRepository: IUserRepository,
  invitationRepository: IInvitationRepository
): ActionTree<IUsersState, IRootState> => ({
  async fetchProfile({ commit, state }): Promise<void> {
    if (state.profileLoading) {
      // only load once at a time
      return;
    }
    commit("setLoadingProfile", true);
    commit("setProfileError", false);
    try {
      const profile = await userRepository.fetchProfile();
      commit("updateProfile", profile);
      commit("setLoadingProfile", false);
    } catch (err) {
      commit("setLoadingProfile", false);
      commit("setProfileError", true);
      throw err;
    }
  },

  async fetchUserList({ commit }, orgaId: string): Promise<void> {
    commit("setUsersLoading", true);
    commit("setUsersLoadingError", false);
    try {
      const users = await userRepository.fetchUsersOfOrganization(orgaId);
      commit("setUsers", users);
    } catch (err) {
      commit("setUsersLoadingError", true);
      throw err;
    } finally {
      commit("setUsersLoading", false);
    }
  },

  async inviteUser(
    _params,
    { orgaId, invitation }: { orgaId: string; invitation: NewInvitationUiDto }
  ): Promise<boolean> {
    return await userRepository.inviteUser(orgaId, invitation);
  },

  async renameProfile({ commit }, name: string): Promise<void> {
    commit("setUpdatingProfile", true);
    try {
      const profile = await userRepository.renameProfile(name);
      commit("updateProfile", profile);
      // eslint-disable-next-line no-useless-catch
    } catch (err) {
      // if (err instanceof ValidationError) {
      throw err;
      // }
    } finally {
      commit("setUpdatingProfile", false);
    }
  },

  async fetchInvitations({ commit }): Promise<void> {
    commit("setInvitationsLoading", true);
    commit("setInvitationsLoadingError", false);
    try {
      const invitations = await invitationRepository.fetch();
      commit("updateInvitations", invitations);
    } catch (err) {
      commit("setInvitationsLoadingError", true);
      throw err;
    } finally {
      commit("setInvitationsLoading", false);
    }
  },

  async acceptInvitation({ commit }, invitation: InvitationUiDto): Promise<void> {
    await invitationRepository.accept(invitation);
    const invitations = await invitationRepository.fetch(); // TODO: remove if singalR is set up
    commit("updateInvitations", invitations);
  },

  async rejectInvitation({ commit }, invitation: InvitationUiDto): Promise<void> {
    await invitationRepository.reject(invitation);
    const invitations = await invitationRepository.fetch(); // TODO: remove if singalR is set up
    commit("updateInvitations", invitations);
  },

  async fetchOrganizations({ commit }): Promise<void> {
    commit("setOrganizationsLoading", true);
    commit("setOrganizationsLoadingError", false);
    try {
      const organizations = await userRepository.fetchOrganizations();
      commit("setOrganizations", organizations);
      commit("setOrganizationsDidLoad", true);
    } catch (err) {
      commit("setOrganizationsLoadingError", true);
      throw err;
    } finally {
      commit("setOrganizationsLoading", false);
    }
  },

  async updateInvitation(
    { commit },
    { invitationId, invitation }: { invitationId: string; invitation: InvitationChangesUiDto }
  ): Promise<UserInOrganizationUiDto> {
    const response = await userRepository.updateInvitation(invitationId, invitation);
    commit("updateInvitation", response);

    return response;
  },

  async removeInvitation(
    { commit },
    { orgaId, invitationId }: { orgaId: string; invitationId: string }
  ): Promise<void> {
    await userRepository.removeInvitation(orgaId, invitationId);
    commit("removeInvitation", invitationId);
  },

  async updateOrganizationUser(
    { commit },
    { orgaId, userId, user }: { orgaId: string; userId: string; user: OrganizationUserChangesUiDto }
  ): Promise<UserInOrganizationUiDto> {
    const response = await userRepository.updateUser(orgaId, userId, user);
    commit("updateUserInOrganization", response);

    return response;
  },

  async removeOrganizationUser({ commit }, { orgaId, userId }: { orgaId: string; userId: string }): Promise<void> {
    await userRepository.removeUser(orgaId, userId);
    commit("removeUserFromOrganization", userId);
  },

  async invitationChanged(
    { commit },
    {
      item,
      changeAction,
    }: {
      item: UserInOrganizationUiDto;
      changeAction: ChangeAction;
    }
  ): Promise<void> {
    switch (changeAction) {
      case ChangeAction.Add:
        commit("addUserInOrganization", item);
        break;
      case ChangeAction.Update:
        commit("updateUserInOrganization", item);
        break;
      case ChangeAction.Remove:
        commit("removeUserFromOrganizationViaSignal", item);
        break;
      default:
        throw new Error(`Undefined changeAction value ${changeAction} for invitations.`);
    }
  },

  async organizationAssignmentChanged({ commit }, { item }: { item: AssignedOrganizationUiDto }): Promise<void> {
    commit("updateAssignedOrga", item);
  },

  async userChanged(
    { commit },
    { item, changeAction }: { item: UserInOrganizationUiDto; changeAction: ChangeAction }
  ): Promise<void> {
    switch (changeAction) {
      case ChangeAction.Add:
        commit("addUserInOrganization", item);
        break;
      case ChangeAction.Update:
        commit("updateUserInOrganization", item);
        break;
      case ChangeAction.Remove:
        commit("removeUserFromOrganizationViaSignal", item);
        break;
      default:
        throw new Error(`Undefined changeAction value ${changeAction} for invitations.`);
    }
  },
});












import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";

import PluginsDownloadCard from "@/components/PluginsDownloadCard.vue";
import pluginsRepository from "@/repositories/pluginsRepository";
import { ExternalBundleUiDto } from "@/models/ExternalBundleUiDto";

export default defineComponent({
  name: "PluginsDownload",

  components: {
    PluginsDownloadCard,
  },

  data() {
    return {
      error: false,
      loading: true,
      externalModules: undefined as ExternalBundleUiDto[] | undefined,
      extronUrl: undefined as string | undefined,
    };
  },

  mounted() {
    this.fetchPlugins();
  },

  computed: {
    ...mapGetters({
      currentOrganizationId: "currentOrganizationId",
    }),
  },

  methods: {
    async fetchPlugins() {
      this.loading = true;
      this.error = false;
      try {
        const result = await pluginsRepository.fetchModules(this.currentOrganizationId);
        this.extronUrl = result.links.find((link) => link.name === "Extron driver")?.url;
        this.externalModules = result.bundles;
      } catch (err) {
        this.error = true;
      }
      this.loading = false;
    },
  },
});

import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "./texts/en";

Vue.use(VueI18n);

// For the forseeable future is is only english, so this is enough
export default new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { en },
});

import urls from "@/config/urls";

import Repository from "./repository";
import { getJsonData } from "./helpers";

import { InvitationUiDto } from "@/models/InvitationUiDto";

export interface IInvitationRepository {
  accept(invitation: InvitationUiDto): Promise<boolean>;
  fetch(): Promise<InvitationUiDto[]>;
  reject(invitation: InvitationUiDto): Promise<boolean>;
}

class InvitationRepository implements IInvitationRepository {
  public async fetch(): Promise<InvitationUiDto[]> {
    const response = await Repository.get(urls.api.invitations.all);

    return getJsonData(response) as InvitationUiDto[];
  }

  public async accept(invitation: InvitationUiDto): Promise<boolean> {
    await Repository.put(urls.api.invitations.accept(invitation.invitationId));

    return true;
  }

  public async reject(invitation: InvitationUiDto): Promise<boolean> {
    await Repository.put(urls.api.invitations.reject(invitation.invitationId));

    return true;
  }
}

export default new InvitationRepository();

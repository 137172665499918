// tslint:disable

/**
 *
 */
export enum AccountType {
  /**
   *
   */
  Owner = 1,

  /**
   *
   */
  Admin = 2,

  /**
   *
   */
  Moderator = 3,
}





















import { defineComponent } from "@vue/composition-api";

import { AssignedOrganizationUiDto } from "@/models/AssignedOrganizationUiDto";
import { AccountType } from "@/models/AccountType";

export default defineComponent({
  name: "OrganizationSelector",

  computed: {
    organization(): AssignedOrganizationUiDto | null {
      return this.$store.getters["currentOrganization"];
    },

    organizations(): AssignedOrganizationUiDto[] {
      return this.$store.getters["users/organizations"];
    },
    getOrganization(): (id: string) => AssignedOrganizationUiDto {
      return this.$store.getters["users/getOrganization"];
    },

    currentValue(): string {
      return (this.organization ? this.organization.id : "") || "";
    },

    items(): object[] {
      const orgas =
        this.organizations.length > 0
          ? this.organizations.map((org: AssignedOrganizationUiDto) => ({
              text: org.name,
              value: org.id,
            }))
          : [{ text: this.$t("components.organizationSelector.noItems"), value: "" }];
      return [
        ...orgas,
        { divider: true, value: "-" },
        { text: this.$t("components.organizationSelector.create"), icon: "add", value: "__CREATE__" },
      ];
    },
  },

  methods: {
    selectOrganization(orga: AssignedOrganizationUiDto | null): void {
      this.$store.dispatch("ui/selectOrganization", orga);
    },

    openCreateOrganization(): void {
      this.$store.dispatch("organizations/openCreateOrganization");
    },

    async handleChange(value: string): Promise<void> {
      switch (value) {
        case "":
          break;
        case "__CREATE__":
          this.selectOrganization(null);
          this.openCreateOrganization();
          this.$router.push({ name: "createManager" });
          break;
        default:
          const selectedOrga = this.getOrganization(value);
          if (this.$router.currentRoute.name !== "profile" && selectedOrga.accountType !== AccountType.Owner) {
            this.$router.push({ name: "profile" });
            window.setTimeout(() => {
              this.selectOrganization(selectedOrga);
            }, 100);
          } else {
            this.selectOrganization(selectedOrga);
          }
          (this.$refs.select as HTMLSelectElement).blur();
      }
    },
  },
});






import { defineComponent } from "@vue/composition-api";

import urls from "@/config/urls";

export default defineComponent({
  name: "WhatsNewLink",

  computed: {
    documentationUrl(): string {
      return this.$store.getters["system/documentationUrl"];
    },

    link(): String {
      return urls.docs.releaseNotes(this.documentationUrl);
    },
  },
});

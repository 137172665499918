import { MutationTree } from "vuex";

import { IOrganizationsState } from "./types";
import { ExistingOrganizationUiDto } from "@/models/ExistingOrganizationUiDto";

export const mutations: MutationTree<IOrganizationsState> = {
  loadOrg(state: IOrganizationsState, orga: ExistingOrganizationUiDto): void {
    state.organization = orga;
  },

  updateOrg(state: IOrganizationsState, orga: ExistingOrganizationUiDto | null): void {
    state.organization = {
      ...state.organization,
      ...(orga || ({} as ExistingOrganizationUiDto)),
    };
  },

  setLoadingOrg(state: IOrganizationsState, value: boolean): void {
    state.loading = value;
  },

  setUpdating(state: IOrganizationsState, value: boolean): void {
    state.updating = value;
  },

  setError(state: IOrganizationsState, value: boolean): void {
    state.error = value;
  },
};

import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";

import App from "./components/App/App.vue";
import router from "./router/index";
import store from "./store/index";

import vuetify from "./plugins/vuetify";

import {
  McmButton,
  McmCanDo,
  McmCard,
  McmCardHeader,
  McmContent,
  McmDialog,
  McmErrorPage,
  McmField,
  McmHeader,
  McmHeaderTabs,
  McmInfoIcon,
  McmLabel,
  McmLogo,
  McmNavigation,
  McmPageFooter,
  McmPrimaryButton,
  McmSearchInput,
  McmSecondaryButton,
  McmSidebarLayout,
} from "../modules/mcm-vue-components";

import i18n from "./config/i18n";

// Registering some global variables
Vue.component("mcm-button", McmButton);
Vue.component("mcm-can-do", McmCanDo);
Vue.component("mcm-card", McmCard);
Vue.component("mcm-card-header", McmCardHeader);
Vue.component("mcm-content", McmContent);
Vue.component("mcm-error-page", McmErrorPage);
Vue.component("mcm-dialog", McmDialog);
Vue.component("mcm-field", McmField);
Vue.component("mcm-header", McmHeader);
Vue.component("mcm-header-tabs", McmHeaderTabs);
Vue.component("mcm-info-icon", McmInfoIcon);
Vue.component("mcm-label", McmLabel);
Vue.component("mcm-logo", McmLogo);
Vue.component("mcm-navigation", McmNavigation);
Vue.component("mcm-page-footer", McmPageFooter);
Vue.component("mcm-primary-button", McmPrimaryButton);
Vue.component("mcm-search-input", McmSearchInput);
Vue.component("mcm-secondary-button", McmSecondaryButton);
Vue.component("mcm-sidebar-layout", McmSidebarLayout);

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Fragment',[(_vm.showSlot)?_vm._t("default"):_c('McmErrorPage',{attrs:{"inline":true,"error":{
      code: 403,
      subtitle: 'errors.403.subtitle',
      title: 'errors.403.title',
      message: 'errors.403.message',
      action: {
        title: 'errors.403.action',
        callback: function () {
          if (_vm.alternativeRoute) {
            this$1.$router.push({
              name: _vm.alternativeRoute,
            });
          } else {
            this$1.relogin();
          }
        },
      },
      type: 'Forbidden',
    }}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
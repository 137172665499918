import { onMounted } from "@vue/composition-api";
import { useI18n } from "@/helpers";

export default function setPageTitle(titleKey: string): void {
  const { t } = useI18n();

  onMounted(() => {
    document.title = t(titleKey).toString();
  });
}

import Vue from "vue";
import Router from "vue-router";

import * as routerMiddlewares from "@/router/middlewares";

import CreateManager from "@/views/CreateManager.vue";
import SoftwareDownloadsPage from "@/views/SoftwareDownloadsPage.vue";
import DocumentationPage from "@/views/Documentation.vue";
import OverviewPage from "@/views/OverviewPage.vue";
import ProfilePage from "@/views/ProfilePage.vue";
import MembersPage from "@/views/MembersPage.vue";
import LoginCallback from "@/views/LoginCallback.vue";
import Logout from "@/views/Logout.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/overview",
      name: "root",
    },
    {
      path: "/overview",
      name: "overview",
      component: OverviewPage,
      meta: {
        requiresAuth: true,
        scrollToTop: true,
      },
    },
    {
      path: "/downloads",
      redirect: "/downloads/manager",
      name: "downloads",
    },
    {
      path: "/downloads/manager",
      name: "managerDownload",
      component: SoftwareDownloadsPage,
      meta: {
        requiresAuth: true,
        scrollToTop: true,
      },
    },
    {
      path: "/downloads/standalone",
      name: "standaloneDownload",
      component: SoftwareDownloadsPage,
      meta: {
        requiresAuth: true,
        scrollToTop: true,
      },
    },
    {
      path: "/downloads/plugins",
      name: "pluginsDownload",
      component: SoftwareDownloadsPage,
      meta: {
        requiresAuth: true,
        scrollToTop: true,
      },
    },
    {
      path: "/createManager",
      name: "createManager",
      component: CreateManager,
      meta: {
        requiresAuth: true,
        scrollToTop: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: ProfilePage,
      meta: {
        requiresAuth: true,
        scrollToTop: true,
      },
    },
    {
      path: "/members",
      name: "members",
      component: MembersPage,
      meta: {
        requiresAuth: true,
        scrollToTop: true,
      },
    },
    {
      path: "/login",
      name: "loginCallback",
      component: LoginCallback,
      meta: {
        isLoginRoute: true,
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
    },
    {
      path: "/documentation",
      name: "documentation",
      component: DocumentationPage,
      meta: {
        scrollToTop: true,
      },
    },
    {
      path: "/imprint",
      name: "imprint",
      component: () => import(/* webpackChunkName: "Imprint" */ "@/views/Imprint.vue"),
      meta: {
        scrollToTop: true,
      },
    },
    {
      path: "/terms-of-use",
      name: "terms",
      component: () => import(/* webpackChunkName: "TermsOfUse" */ "@/views/TermsOfUse.vue"),
      meta: {
        scrollToTop: true,
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () => import(/* webpackChunkName: "Privacy" */ "@/views/Privacy.vue"),
      meta: {
        scrollToTop: true,
      },
    },
    {
      path: "/beta-access",
      name: "betaAccess",
      component: () => import(/* webpackChunkName: "BetaAccess" */ "@/views/BetaAccess.vue"),
      meta: {
        requiresAuth: true,
        scrollToTop: true,
      },
    },
    {
      path: "/*",
      name: "not-found",
      component: NotFound,
    },
  ],
});

router.beforeEach(routerMiddlewares.loadSettings(router));
router.beforeEach(routerMiddlewares.redirectForLoggedInOnly);
router.beforeEach(routerMiddlewares.redirectForLoggedOutOnly);
router.beforeEach(routerMiddlewares.loadOrgasForAuthorization);
router.beforeEach(routerMiddlewares.scrollToTop);

export default router;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mcm-card',{attrs:{"title":_vm.$t('components.pluginsDownloadCard.title'),"airy":true,"refs":"card","loading":_vm.loading}},[_c('p',{staticClass:"main-description"},[_vm._v(_vm._s(_vm.$t("components.pluginsDownloadCard.description")))]),_c('hr',{staticClass:"card-divider"}),_vm._l((_vm.externalModules),function(pkg){return _c('section',{key:pkg.name},[_c('div',{staticClass:"with-image"},[_c('img',{staticClass:"logo",attrs:{"src":pkg.logo,"alt":"","width":"182"}}),_c('div',{staticClass:"with-image__content"},[_c('h3',[_vm._v(_vm._s(pkg.name))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("components.pluginsDownloadCard.crestron.version", { version: pkg.version, }))+" ")])])]),_c('p',[_c('v-checkbox',{attrs:{"value":_vm.licenseAgreements[pkg.name],"hide-details":true},on:{"change":function($event){return _vm.handleChange(pkg, $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('components.pluginsDownloadCard.crestron.licenceLabel', {
                name: pkg.name,
                licenseLink: pkg.license,
              }).toString()
            )},on:{"click":function($event){$event.stopPropagation();return (function () {}).apply(null, arguments)}}})]},proxy:true}],null,true)})],1),_c('mcm-primary-button',{attrs:{"disabled":!_vm.licenseAgreements[pkg.name]},on:{"click":function () { return _vm.startModuleDownload(pkg); }}},[_vm._v(" "+_vm._s(_vm.$t("components.pluginsDownloadCard.crestron.button"))+" ")]),_c('hr',{staticClass:"card-divider"})],1)}),_c('section',{staticClass:"with-image"},[_c('img',{staticClass:"extron-logo",attrs:{"src":require("../assets/extron-logo.png"),"alt":"","width":"140","height":"80"}}),_c('div',{staticClass:"with-image__content"},[_c('h3',[_vm._v(_vm._s(_vm.$t("components.pluginsDownloadCard.extron.title")))]),_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.$t('components.pluginsDownloadCard.extron.description', {
            link: _vm.extronUrl,
          })
        )}})])]),_c('hr',{staticClass:"card-divider"}),_c('section',[_c('h3',[_vm._v(_vm._s(_vm.$t("components.pluginsDownloadCard.other.title")))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('components.pluginsDownloadCard.other.description'))}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }




import { defineComponent } from "@vue/composition-api";

import { McmErrorPage, TotalErrorMessage } from "../../modules/mcm-vue-components";

export default defineComponent({
  name: "PageNotFound",

  components: {
    McmErrorPage,
  },

  computed: {
    error(): TotalErrorMessage {
      return {
        code: 404,
        subtitle: "errors.404.subtitle",
        title: "errors.404.title",
        message: "errors.404.message",
        action: {
          title: "errors.404.action",
          callback: () => this.$router.push({ name: "overview" }),
        },
        type: "NotFound",
      };
    },
  },
});













import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";

import { ManagerVersionUiDto } from "@/models/ManagerVersionUiDto";

import DownloadCard from "./DownloadCard.vue";

export default defineComponent({
  name: "ManagerDownloadCard",

  components: {
    DownloadCard,
  },

  props: {
    showFeaturesEnabled: { type: Boolean, default: false },
  },

  mounted() {
    this.reload();
  },

  computed: {
    ...mapGetters({
      currentOrganizationId: "currentOrganizationId",
      isOrganizationReady: "organizations/isOrgaReady",
    }),

    checkboxes(): Array<(v: ManagerVersionUiDto) => string | false> {
      return [
        () =>
          this.$t("components.downloadCard.privacyCheckboxLabel", {
            termsLink: "/terms-of-use/",
            privacyLink: "/privacy/",
          }).toString(),
        () => this.$t("components.downloadCard.licenseCheckboxLabel", { link: "/terms-of-use/" }).toString(),
        (selectedVersion) => selectedVersion.isBeta && this.$t("components.downloadCard.betaCheckboxLabel").toString(),
      ];
    },

    managerVersions(): ManagerVersionUiDto[] {
      return this.$store.getters["system/managerVersions"][this.currentOrganizationId] || [];
    },

    managerBetaVersions(): ManagerVersionUiDto[] {
      return this.$store.getters["system/managerBetaVersions"][this.currentOrganizationId];
    },
  },

  methods: {
    fetchManagerVersions(): Promise<void> {
      return this.$store.dispatch("system/fetchManagerVersions", {
        orgaId: this.currentOrganizationId,
        internal: this.showFeaturesEnabled,
      });
    },

    async reload() {
      await this.fetchManagerVersions();
    },
  },

  watch: {
    currentOrganizationId() {
      this.reload();
    },
  },
});

import { MutationTree } from "vuex";
import * as Msal from "@azure/msal-browser";

import { IIdentityState } from "./types";
import { tokens } from "@/repositories/repository";
import { LoginToken } from "@/services/MsalAuthService";

export const mutations: MutationTree<IIdentityState> = {
  setUserCore(
    state: IIdentityState,
    { user, token }: { user: Msal.AccountInfo | null; token: LoginToken | undefined | null }
  ) {
    tokens.authToken = token ? token.accessToken : "";

    state.user = user;
    state.isAuthenticated = !!tokens.authToken;
    state.hasAdminAccess = !!token?.adminAccess;
  },

  deleteUserCore(state: IIdentityState): void {
    tokens.authToken = "";
    state.user = null;
    state.isAuthenticated = false;
  },

  setInitialized(state: IIdentityState): void {
    state.initialized = true;
  },
};

import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IOrganizationsState } from "./types";

import { IOrganizationRepository } from "@/repositories/organizationRepository";
import { ExistingOrganizationUiDto } from "@/models/ExistingOrganizationUiDto";
import { ChangeAction } from "@/models/ChangeAction";
import ValidationError from "@/repositories/ValidationError";

export const createActions = (
  organizationRepository: IOrganizationRepository
): ActionTree<IOrganizationsState, IRootState> => ({
  async openCreateOrganization({ commit }): Promise<void> {
    commit("updateOrg", {});
  },

  async fetchOrganization({ commit }, id: string): Promise<void> {
    commit("setLoadingOrg", true);
    commit("setError", false);
    try {
      const organization = await organizationRepository.fetchById(id);
      commit("loadOrg", organization);
    } catch (err) {
      commit("setError", true);
      throw err;
    } finally {
      commit("setLoadingOrg", false);
    }
  },

  async createOrUpdate({ commit }, data: ExistingOrganizationUiDto): Promise<ExistingOrganizationUiDto | undefined> {
    commit("setUpdating", true);
    try {
      const orga = await organizationRepository.createOrUpdate(data);
      commit("updateOrg", orga);
      return orga;
    } catch (err) {
      if (err instanceof ValidationError) {
        throw err;
      }
    } finally {
      commit("setUpdating", false);
    }
  },

  async receiveUpdate(
    { commit },
    {
      item,
      changeAction,
    }: {
      item: ExistingOrganizationUiDto;
      changeAction: ChangeAction;
    }
  ): Promise<void> {
    switch (changeAction) {
      case ChangeAction.Add:
        commit("updateOrg", item);
        break;
      case ChangeAction.Update:
        commit("updateOrg", item);
        break;
      case ChangeAction.Remove:
        commit("removeOrg", item);
        break;
      default:
        throw new Error(`Undefined changeAction value ${changeAction} for organizations.`);
    }
  },
});











import { Component, Vue, Prop } from "vue-property-decorator";

import McmCard from "./McmCard.vue";

@Component({
  components: {
    McmCard
  }
})
export default class McmDialog extends Vue {
  @Prop() public error?: string;
  @Prop() public subtitle!: string;
  @Prop() public title!: string;
  @Prop() public value!: boolean;
  @Prop({ default: false }) public loading!: boolean;
  @Prop({ default: 500 }) public width!: number;

  mounted() {
    const button = (this.$refs.ref as any)?.$el?.querySelector("button");
    setTimeout(() => {
      if (button) {
        button.focus();
      }
    }, 300);
  }
}

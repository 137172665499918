




import { defineComponent } from "@vue/composition-api";
import Vue from "vue";
import queryString from "query-string";

import MsalAuthService, { AuthError } from "@/services/MsalAuthService";
import { SettingsUiDto } from "../models/SettingsUiDto";
import LoginLoopDetector from "@/services/LoginLoopDetector";

export default defineComponent({
  name: "LoginCallback",

  data() {
    return {
      authService: undefined as MsalAuthService | undefined,
    };
  },

  async beforeMount() {
    await this.handleLoginLoop();

    const settings = await this.fetchSettings();
    this.authService = new MsalAuthService(settings);
    try {
      await this.authService.processLoginCallback();

      this.login();
    } catch (err) {
      this.handleError(err);
    }
  },

  methods: {
    //@Action("fetchSettings", { namespace: "system" }) private fetchSettings!: ActionWithoutParameter<SettingsUiDto>;
    fetchSettings(): SettingsUiDto {
      return this.$store.dispatch("system/fetchSettings") as unknown as SettingsUiDto;
    },
    raiseError(error: string): void {
      this.$store.dispatch("ui/raiseError", error);
    },

    async login(): Promise<void> {
      const authService = this.authService as MsalAuthService;
      const params = queryString.parse(window.location.search);
      const email = (params?.email as string) || "";

      try {
        if (!authService.getUser()) {
          await authService.login({ email });
        }
        this.$router.push({ name: "overview" });
      } catch (err) {}
    },

    handleError(error: AuthError): void {
      const msgSplit = error.message.split(": ");
      const errorCode = msgSplit[1];
      switch (errorCode) {
        case "AADB2C90118":
          this.doPasswordReset();
          break;
        case "AADB2C90091":
          this.login();
          break;
        default:
          this.triggerError(error);
      }
    },

    async doPasswordReset() {
      (this.authService as MsalAuthService).passwordReset();
    },

    triggerError(exception: Error) {
      if (Vue.appInsights) {
        Vue.appInsights.trackException({
          exception,
        });
      }
      this.raiseError("errors.authCallbackError");
      setTimeout(() => {
        window.location.hash = "";
        window.location.reload();
      }, 2500);
    },

    async handleLoginLoop(): Promise<void> {
      const loopDetector = new LoginLoopDetector();

      if (loopDetector.isLooping()) {
        const settings = await this.fetchSettings();
        this.authService = new MsalAuthService(settings);
        this.authService.logout();

        loopDetector.reset();
      }
    },
  },
});











































import { defineComponent } from "@vue/composition-api";

import { ACTION_DOWNLOADS_PAGE_SHOW, ACTION_MANAGER_DOWNLOAD_SHOW } from "@/config/authActions";

import Manager from "./Manager.vue";
import StandaloneDownload from "./StandaloneDownload.vue";
import OrganizationCard from "@/components/OrganizationCard.vue";
import PageHeader from "@/components/PageHeader.vue";
import PluginsDownload from "./PluginsDownload.vue";

export default defineComponent({
  name: "SoftwareDownloadsPage",

  components: {
    Manager,
    OrganizationCard,
    PageHeader,
    PluginsDownload,
    StandaloneDownload,
  },

  data() {
    return {
      activeTab: "/downloads/manager",
    };
  },

  mounted() {
    this.updatePageTitle();
  },

  computed: {
    canDoRule() {
      return ACTION_DOWNLOADS_PAGE_SHOW;
    },

    canSeeManagerDownload(): boolean {
      return this.$store.getters.canDo(ACTION_MANAGER_DOWNLOAD_SHOW);
    },

    canSeePage(): boolean {
      return this.$store.getters.canDo(this.canDoRule);
    },
  },

  watch: {
    activeTab() {
      this.updatePageTitle();
    },
  },

  methods: {
    updatePageTitle() {
      if (this.activeTab === "/downloads/standalone") {
        document.title = this.$t("views.standaloneDownload.pageTitle").toString();
      } else {
        document.title = this.$t("views.manager.pageTitle").toString();
      }
    },
  },
});

import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IUiState, SnackbarView } from "./types";

export const getters: GetterTree<IUiState, IRootState> = {
  currentOrganizationId: (state: IUiState): string | null => state.selectedOrganizationId,
  currentSnack: (state: IUiState): SnackbarView => state.snackbars && state.snackbars[0],
  error: (state: IUiState) => state.error || null,

  isNavigationOpen: (state: IUiState) => !!state.navigationOpen,
  isPageRequiringAuth: (state: IUiState) => state.pageRequiresAuth,
  showIntroduction: (state: IUiState) => !state.introductionHidden,
};

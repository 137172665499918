import urls from "@/config/urls";

import Repository from "./repository";
import { getJsonData } from "./helpers";

import { ExistingOrganizationUiDto } from "@/models/ExistingOrganizationUiDto";
import { NewOrganizationUiDto } from "@/models/NewOrganizationUiDto";

export interface IOrganizationRepository {
  createOrUpdate(data: ExistingOrganizationUiDto): Promise<ExistingOrganizationUiDto>;
  fetchById(id: string): Promise<ExistingOrganizationUiDto>;
}

class OrganizationRepository implements IOrganizationRepository {
  public async fetchById(id: string): Promise<ExistingOrganizationUiDto> {
    const response = await Repository.get(urls.api.organizations.get(id));

    return getJsonData<ExistingOrganizationUiDto>(response);
  }

  public async createOrUpdate(
    data: ExistingOrganizationUiDto | NewOrganizationUiDto
  ): Promise<ExistingOrganizationUiDto> {
    let response;

    if ((data as ExistingOrganizationUiDto).id) {
      response = await Repository.patch(
        urls.api.organizations.update((data as ExistingOrganizationUiDto).id as string),
        data
      );
    } else {
      response = await Repository.post(urls.api.organizations.all, data);
    }

    return getJsonData<ExistingOrganizationUiDto>(response);
  }
}

export default new OrganizationRepository();

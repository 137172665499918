import HubConnector, { ConnectionConfiguration } from "./HubConnector";
import urls from "@/config/urls";
import { createSingletonFactory } from "@/helpers";
import { Store } from "vuex";
import { IRootState } from "@/store/types";

export const organizationConnection: ConnectionConfiguration = {
  events: {
    OrganizationUpdated: "organizations/receiveUpdate",
  },
  signals: {
    RefreshOrganizationData: "handleRefreshSignal",
  },
  loadError: "organizationsLoadError",
  urlEndpoint: urls.hub.organizations,
};

// export const organizationConnection: ConnectionConfiguration = {
//   eventName: "OrganizationUpdated",
//   refreshEventName: "RefreshOrganizationData",
//   loadError: "organizationsLoadError",
//   storeUpdater: "organizations/receiveUpdate",
//   urlEndpoint: urls.hub.organizations,
// };

export default class OrganizationConnector extends HubConnector {
  constructor(store: Store<IRootState> | null = null) {
    super(organizationConnection, store);
  }

  protected async onStartConnection(): Promise<void> {
    try {
      await this.store.dispatch("users/fetchOrganizations");
    } catch (err) {
      // It will be handled in the Dashboard
    }
  }

  protected async onRefresh(): Promise<void> {
    await this.store.dispatch("users/fetchOrganizations");
  }
}

const createOrganizationConnector = createSingletonFactory<OrganizationConnector>(OrganizationConnector);

export { createOrganizationConnector };

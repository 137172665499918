





















































import { defineComponent, PropType } from "@vue/composition-api";
import { mapGetters } from "vuex";
import sortBy from "lodash/sortBy";

import systemRepository from "@/repositories/systemRepository";
import { ManagerVersionUiDto } from "@/models/ManagerVersionUiDto";

import WhatsNewLink from "./WhatsNewLink.vue";
import { StandaloneFirmwareVersionUiDto } from "@/models/StandaloneFirmwareVersionUiDto";

interface SelectItem {
  text: string;
  description: string;
  value: string;
}

type VersionDto = ManagerVersionUiDto | StandaloneFirmwareVersionUiDto;
type CheckboxGetter = (v: VersionDto) => string | false;

export default defineComponent({
  name: "DownloadCard",

  components: {
    WhatsNewLink,
  },

  props: {
    loading: { type: Boolean, default: false },
    showFeaturesEnabled: { type: Boolean, default: false },
    i18nPrefix: { type: String, required: true },
    checkboxes: { type: Array as PropType<CheckboxGetter[]>, required: true },
    versions: {
      type: Array as PropType<VersionDto[]>,
      default: [],
    },
    betaVersions: { type: Array as PropType<VersionDto[]> },
  },

  data() {
    return {
      checkboxValues: [] as boolean[],
      version: "",
      selectedVersion: {} as VersionDto,
    };
  },

  computed: {
    ...mapGetters({
      currentOrganizationId: "currentOrganizationId",
    }),

    downloadButtonEnabled(): boolean {
      return this.visibleCheckboxes.length > 0 && this.visibleCheckboxes.every((_v, i) => !!this.checkboxValues[i]);
    },

    visibleCheckboxes(): string[] {
      return this.checkboxes.map((check: CheckboxGetter) => check(this.selectedVersion)).filter((x) => x) as string[];
    },

    showingBetaVersions(): boolean {
      return (this.betaVersions || []).length > 0;
    },

    newestNonBetaVersion(): VersionDto | undefined {
      return sortBy(this.versions, "releaseDate")
        .reverse()
        .find((version: VersionDto) => !version.isBeta);
    },

    allVersions(): VersionDto[] {
      return [this.newestNonBetaVersion!, ...this.betaVersions!];
    },

    showFeaturesVersions(): SelectItem[] {
      return this.versions.map((info: VersionDto) => ({
        text: info.version,
        description: (info as ManagerVersionUiDto).description || "",
        value: info.version,
      }));
    },

    selectableVersions(): SelectItem[] {
      return this.allVersions.map((info: VersionDto) => ({
        text: `${(info as ManagerVersionUiDto).description || info.version} (${info.isBeta ? "Beta" : "Release"})`,
        description: (info as ManagerVersionUiDto).description,
        value: info.version,
      }));
    },
  },

  methods: {
    changeCheckbox(index: number, value: boolean) {
      this.$set(this.checkboxValues, index, value);
    },

    handleLinkClick(event: MouseEvent): void {
      if ((event.target as HTMLElement).tagName.toLowerCase() === "a") {
        event.stopPropagation();
      }
    },

    startDownload(): void {
      if ((this.selectedVersion as any as StandaloneFirmwareVersionUiDto).downloadLink) {
        window.open((this.selectedVersion as any as StandaloneFirmwareVersionUiDto).downloadLink.toString(), "_blank");
        return;
      }
      systemRepository.downloadManager(this.currentOrganizationId || "", this.selectedVersion.version);
    },

    updateManagerVersion(): void {
      if (this.showingBetaVersions) {
        this.version = this.betaVersions ? this.betaVersions[0]?.version : "";
      } else if (this.showFeaturesEnabled) {
        this.version = this.versions[0].version;
      } else {
        this.selectedVersion = this.newestNonBetaVersion! || {};
      }
    },
  },

  watch: {
    version() {
      this.selectedVersion =
        (this.showFeaturesEnabled ? this.versions : this.allVersions).find(
          (info: VersionDto) => info.version === this.version
        ) || ({} as VersionDto);
      this.checkboxValues = this.checkboxes.map(() => false);
    },

    versions() {
      this.updateManagerVersion();
    },

    checkboxes() {
      this.checkboxValues = this.checkboxes.map(() => false);
    },
  },
});

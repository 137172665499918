





import { Vue, Component, Prop } from "vue-property-decorator";

import Logo from "./assets/sennheiser-logo.svg";
import LogoNeg from "./assets/sennheiser-logo-neg.svg";

@Component({
  components: {
    Logo,
    LogoNeg
  }
})
export default class McmLogo extends Vue {
  @Prop({ default: false }) public dark!: boolean;
  @Prop({ default: 25 }) public height!: string | number;
  @Prop({ default: 34 }) public width!: string | number;
}

import urls from "@/config/urls";

import Repository from "./repository";
import { getJsonData } from "./helpers";

import { SettingsUiDto } from "@/models/SettingsUiDto";
import { downloadRequest } from "../../modules/mcm-vue-components";
import { ManagerVersionsUiDto } from "@/models/ManagerVersionsUiDto";
import { StandaloneFirmwareVersionsUiDto } from "@/models/StandaloneFirmwareVersionsUiDto";
import { DocumentUiDto } from "@/models/DocumentUiDto";
import { DocumentsUiDto } from "@/models/DocumentsUiDto";

export interface ISystemRepository {
  downloadManager(orgaId: string, version: string): Promise<unknown>;
  fetchSettings(): Promise<SettingsUiDto>;
  fetchStandaloneVersions(orgaId: string): Promise<StandaloneFirmwareVersionsUiDto>;
  fetchManagerVersions(orgaId: string, internal: boolean): Promise<ManagerVersionsUiDto>;
  fetchLicenseDocs(): Promise<DocumentUiDto[]>;
}

class SystemRepository implements ISystemRepository {
  public async downloadManager(orgaId: string, version: string): Promise<void> {
    await downloadRequest(Repository, urls.api.download(orgaId, version));
  }

  public async fetchStandaloneVersions(orgaId: string): Promise<StandaloneFirmwareVersionsUiDto> {
    const url = orgaId ? urls.api.versions.standalone(orgaId) : urls.api.versions.standaloneWithoutOrga();
    const response = await Repository.get(url);

    return getJsonData(response) as StandaloneFirmwareVersionsUiDto;
  }

  public async fetchManagerVersions(orgaId: string, internal: boolean): Promise<ManagerVersionsUiDto> {
    const url = internal ? urls.api.versions.managerInternal() : urls.api.versions.manager(orgaId);
    const response = await Repository.get(url);

    if (internal) {
      return { versions: getJsonData(response) } as ManagerVersionsUiDto;
    } else {
      return getJsonData(response) as ManagerVersionsUiDto;
    }
  }

  public async fetchSettings(): Promise<SettingsUiDto> {
    const response = await Repository.get(urls.api.settings);

    return getJsonData(response) as SettingsUiDto;
  }

  public async fetchLicenseDocs(): Promise<DocumentUiDto[]> {
    const response = await Repository.get(urls.api.documents);
    return (getJsonData(response) as DocumentsUiDto).documents as DocumentUiDto[];
  }
}

export default new SystemRepository();

import { render, staticRenderFns } from "./PluginsDownload.vue?vue&type=template&id=f456fdb0&scoped=true&"
import script from "./PluginsDownload.vue?vue&type=script&lang=ts&"
export * from "./PluginsDownload.vue?vue&type=script&lang=ts&"
import style0 from "./PluginsDownload.vue?vue&type=style&index=0&id=f456fdb0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f456fdb0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFlex,VLayout})























import { defineComponent } from "@vue/composition-api";

import DownloadCard from "@/components/DownloadCard.vue";
import OrganizationCard from "@/components/OrganizationCard.vue";
import PageHeader from "@/components/PageHeader.vue";

export default defineComponent({
  name: "CreateManager",

  components: {
    DownloadCard,
    OrganizationCard,
    PageHeader,
  },

  computed: {
    isErrornous(): boolean {
      return this.$store.getters["organizations/isErrornous"];
    },
  },
});

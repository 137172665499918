import Vue from "vue";
import vuex, { StoreOptions } from "vuex";
import { identity } from "./identity/index";
import { organizations } from "./organizations/index";
import { system } from "./system/index";
import { ui } from "./ui/index";
import { users } from "./users/index";
import { IRootState } from "./types";
import { AssignedOrganizationUiDto } from "@/models/AssignedOrganizationUiDto";
import { AccountType } from "@/models/AccountType";
import { AUTHORIZABLE_ACTIONS, CanDoFunction } from "@/config/authActions";

Vue.use(vuex);

const currentOrganization = (state: IRootState): AssignedOrganizationUiDto => {
  if (state.users.organizations.length > 0) {
    return (
      state.users.organizations.find((org: AssignedOrganizationUiDto) => org.id === state.ui.selectedOrganizationId) ||
      state.users.organizations[0]
    );
  } else {
    return { accountType: AccountType.OutsideOrga } as AssignedOrganizationUiDto;
  }
};

export type CanDoMethod = (what: string) => boolean;

function accountTypeCanDo(what: string, accountType: AccountType): boolean {
  if (AUTHORIZABLE_ACTIONS[what] === undefined) {
    throw new Error(`${what} is not defined as action in config/authActions.ts.`);
  }
  if (AUTHORIZABLE_ACTIONS[what].length === 0) {
    return true;
  }
  return (AUTHORIZABLE_ACTIONS[what] as AccountType[]).includes(accountType);
}

export const store: StoreOptions<IRootState> = {
  getters: {
    canDo:
      (state: IRootState): CanDoMethod =>
      (what: string) => {
        if (typeof AUTHORIZABLE_ACTIONS[what] === "function") {
          return (AUTHORIZABLE_ACTIONS[what] as CanDoFunction)(state);
        }
        const { accountType } = currentOrganization(state);
        return accountTypeCanDo(what, accountType);
      },
    currentOrganization,
    currentOrganizationId: (state: IRootState): string | null => currentOrganization(state).id,
  },
  modules: {
    identity,
    organizations,
    system,
    ui,
    users,
  },
};

export default new vuex.Store<IRootState>(store);
